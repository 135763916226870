import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { connect } from '../../hocs'
import routes from '../../constants/routes'
import { sessionInitialised } from '../../state/selectors/session'
import SetPassword from './SetPassword'
import VerifyEmail from './VerifyEmail'
import ForgottenPassword from './ForgottenPassword'
import SAMLAuthTest from '../Debug/SAMLAuthTest'
import Register from './Register'
import ResetPassword from './ResetPassword'
import SignIn from './SignIn'
import SAMLAuthFailure from './SAMLAuthFailure'
import AdminUserSAMLResponse from './AdminUserSAMLResponse'
import MultiFactorAuth from './MultiFactorAuth'
import M365AuthEntryPoint from '../../components/Auth/M365AuthEntryPoint'
import GoogleAuthEntryPoint from '../../components/Auth/GoogleAuthEntryPoint'

const PreSignInSwitch = ({ initialised }) => {
  if (!initialised) {
    return null
  }

  return (
    <Switch>
      <Route
        exact
        path={routes.REGISTER}
        component={Register}
      />
      <Route
        exact
        path={routes.RESET_PASSWORD}
        component={ResetPassword}
      />
      <Route
        exact
        path={routes.VERIFY_EMAIL}
        component={VerifyEmail}
      />
      <Route
        exact
        path={routes.SET_PASSWORD}
        component={SetPassword}
      />
      <Route
        exact
        path={routes.FORGOTTEN_PASSWORD}
        component={ForgottenPassword}
      />
      <Route
        exact
        path={routes.SAML_AUTH_TEST}
        component={SAMLAuthTest}
      />
      <Route
        exact
        path={routes.SAML_AUTH_FAIL}
        component={SAMLAuthFailure}
      />
      <Route
        exact
        path={routes.SAML_ADMIN_USER_AUTH_RESPONSE}
        component={AdminUserSAMLResponse}
      />
      <Route
        exact
        path={routes.MFA_LOGIN}
        component={MultiFactorAuth}
      />
      <Route
        exact
        path={[
          routes.OFFICE_365_AUTH_ENTRY_POINT,
          routes.MESSAGE_INJECTION_M365_AUTH_ENTRY_POINT,
          routes.PHISH_ALERT_M365_AUTH_ENTRY_POINT
        ]}
        component={M365AuthEntryPoint}
      />
      <Route
        exact
        path={routes.GOOGLE_SYNC_AUTH_ENTRY_POINT}
        component={GoogleAuthEntryPoint}
      />
      <Route component={SignIn} />
    </Switch>
  )
}

export default connect(
  sessionInitialised
)(PreSignInSwitch)
