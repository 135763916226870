import React, { useCallback } from 'react'
import { Button, message, Modal } from 'antd'
import I18n from 'i18n-js'
import { useMutation } from 'react-apollo'
import _get from 'lodash/get'

import { REVOKE_GOOGLE_INTEGRATION, REVOKE_M365_INTEGRATION } from '../Queries/Companies'
import { getSettingsFromCompany } from '../../helpers'
import { creators as settingsActions } from '../../state/actions/settings'
import useGlobalState from '../../hooks/useGlobalState'

const MUTATION_BY_SERVICE = {
  microsoft: REVOKE_M365_INTEGRATION,
  google: REVOKE_GOOGLE_INTEGRATION
}
const MUTATION_NAME_BY_SERVICE = {
  microsoft: 'revokeM365Integration',
  google: 'revokeGoogleIntegration'
}

const RevokeIntegrationButton = ({ app, service, trOpt, action, disabled }) => {
  const { updateSettings } = useGlobalState(
    null,
    dispatch => ({
      updateSettings: settings => dispatch(settingsActions.replace(settings))
    })
  )
  const [setRevokeIntegration] = useMutation(MUTATION_BY_SERVICE[service], {
    variables: { app, action, origin: window.location.origin }
  })

  const clickRevoke = useCallback(async () => {
    Modal.confirm({
      title: I18n.t('modalTitle', trOpt),
      content: I18n.t('modalDescription', trOpt),
      okText: I18n.t('common.yes'),
      cancelText: I18n.t('common.no'),
      onOk: async () => {
        try {
          const results = await setRevokeIntegration()
          const company = _get(results, ['data', MUTATION_NAME_BY_SERVICE[service]])
          if (company) {
            const settings = getSettingsFromCompany(company)
            if (settings) {
              updateSettings(settings)
            }
            message.success(I18n.t('successMsg', trOpt))
          }
        } catch (e) {
          message.error(I18n.t('errorMsg', trOpt))
        }
      }
    })
  }, [trOpt, setRevokeIntegration, updateSettings, service])

  return (
    <Button {...{ disabled }} onClick={clickRevoke} type='danger' icon='close-circle'>
      {I18n.t('button', trOpt)}
    </Button>
  )
}
export default RevokeIntegrationButton
