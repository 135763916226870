import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  html, body {
    color: ${props => props.theme.copy};
    font-size: 14px;
    font-weight: 400;
    font-family: ${props => props.theme.baseFont};
    letter-spacing: 0.02em;
    -webkit-font-smoothing: subpixel-antialiased;
  }
  
  .ant-layout {
    background:white;
  }
  
  #root {
    height: 100%;
  }

  p {
    font-family: ${props => props.theme.copyFont};
    &.base-font {
      font-family: unset;
    }
  }

  ul.copy-font {
    font-family: ${props => props.theme.copyFont};
  }

  a {
    color: ${props => props.theme.primary};
  }

  h1 {
    font-weight: 500;
    font-size: 1.6rem;
  }

  h2 {
    font-weight: 500;
    font-size: 1.4rem;
  }

  h3 {
    font-weight: 500;
    font-size: 1.2rem;
  }

  h4 {
    font-weight: 500;
    font-size: 1.1rem;
  }

  h5 {
    font-weight: 500;
    font-size: 1.00rem;
  }

  h6 {
    font-weight: 500;
    font-size: 0.9rem;
  }

  .render-hidden {
    display: none;
  }

  .ant-btn.ant-dropdown-trigger:not(.ant-btn-primary) {
    &:hover, &:focus, &:active {
      color: ${props => props.theme.primary};
      border-color: ${props => props.theme.primary};
    }
  }
  .ant-btn:not(.ant-btn-danger, .ant-btn-link, .ant-btn-primary) {
    &:hover, &:focus {
      color: ${props => props.theme.primary};
      border-color: ${props => props.theme.primary};
    }
  }
  
  .ant-btn-primary {
    background-color: ${props => props.theme.primary};
    border-color: ${props => props.theme.primary};
    color: ${props => props.theme.secondary};

    &:hover, &:active, &:focus {
      background-color: ${({ theme }) => theme.primaryButtonHighlight};
      border-color: ${({ theme }) => theme.primaryButtonHighlight};
      color: ${props => props.theme.secondary};
    }
  }

  .ant-card-actions > li > span > .anticon:hover {
    color: ${props => props.theme.primary};
  }

  .ant-btn-background-ghost.ant-btn-primary {
    border-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};

    &:hover, &:active, &:focus {
      border-color: ${({ theme }) => theme.primaryGhostHighlight};
      color: ${({ theme }) => theme.primaryGhostHighlight};
    }
  }

  .ant-btn-link {
    color: ${props => props.theme.primary};

    &:hover {
      color: ${({ theme }) => theme.primaryGhostHighlight};
    }
    &:hover, &:focus, &:active {
      border-color: transparent;
    }
  }
  
  
  .ant-btn {
    line-height: 2.4 !important;
  }

  .ant-btn > .anticon {
    top: 1px;
    position: relative;
  }
  
  .ant-btn-sm {
    line-height: 1 !important;
  }

  .ant-table {
    overflow-x: auto;
  }

  .ant-table-row-expand-icon {
    line-height: 20px !important;
  }

  .ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
    color: ${props => props.theme.primary};
  }
  .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, 
  .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
    color: ${props => props.theme.primary};
  }

  .ant-pagination-item {
    &:hover, &:focus {
      color: ${props => props.theme.primary};
      border-color: ${props => props.theme.primary};
      
      a {
        color: ${props => props.theme.primary};
      }
    }
  }

  .ant-pagination-item-active {
    color: ${props => props.theme.primary};
    border-color: ${props => props.theme.primary};
    
    a {
      color: ${props => props.theme.primary};
    }

    &:hover, &:focus {
      color: ${({ theme }) => theme.primaryGhostHighlight};
      border-color: ${({ theme }) => theme.primaryGhostHighlight};
      
      a {
        color: ${({ theme }) => theme.primaryGhostHighlight};
      }
    }
  }

  .ant-pagination-next:not(.ant-pagination-disabled),
  .ant-pagination-prev:not(.ant-pagination-disabled) {
    &:hover, &:focus {
      .ant-pagination-item-link {
        color: ${props => props.theme.primary};
        border-color: ${props => props.theme.primary};
      }
    }
  }

  .ant-form-item-required::before {
    color: ${props => props.theme.primary};
  }

  .ant-switch-checked {
    background-color: ${props => props.theme.primary};
  }

  .ant-tabs-nav {
    .ant-tabs-tab-active {
      color: ${props => props.theme.primary};
    }
    .ant-tabs-tab:hover {
      color: ${({ theme }) => theme.primary};
    }
  }
  .ant-tabs-ink-bar {
    background-color: ${props => props.theme.primary};
  }

  .usecure-submenu-popup {
    background-color: ${({ theme }) => theme.nav};

    &> .ant-menu {
      background-color: ${({ theme }) => theme.nav};
    }
  }
  
  .ant-input {
    &:hover, &:focus, &:active {
      border-color: ${({ theme }) => theme.primary};
    }
  }
  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: ${({ theme }) => theme.primary};
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.primary};
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.primary};
    border-color: ${({ theme }) => theme.primary};
  }

  .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
    border-color: ${({ theme }) => theme.primary};
  }
  .ant-select-selection:hover {
    border-color: ${({ theme }) => theme.primary};
  }
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: ${({ theme }) => theme.primaryHighlight}
  }
  .ant-select-dropdown-menu-item-selected {
    background-color: ${({ theme }) => theme.primaryHighlight}
  }

  div[id^="tooltip-anchor"] path {
    fill: ${({ theme }) => theme.primary} !important;
  }

  .ant-tooltip-inner {
    color: rgb(34, 34, 34);
    background-color: white;
    box-shadow: 2px 2px 8px rgb(0 0 0 / 15%)
  }
  .ant-tooltip-arrow::before {
    background-color: white;
  }
  /* Fix for issue in Slate editor where cursor is broken in Chrome 105+. see: https://github.com/ianstormtaylor/slate/issues/5110 */
  @supports not (-apple-trailing-word: inherit) {
    div[data-slate-editor] {
      -webkit-user-modify: read-write !important;
    }
  }
`

export default GlobalStyle
