import React from 'react'
import queryString from 'query-string'
import I18n from 'i18n-js'
import { Card } from 'antd'

import routes from '../../constants/routes'

const trOpt = { scope: 'office365Setup' }

const routeOptions = {
  sync: routes.GOOGLE_SYNC_SETUP
}

const GoogleAuthEntryPoint = () => {
  const search = window.location.search
  const { app, origin } = JSON.parse(window.atob(queryString.parse(search).state || '') || '{}')

  if (app && origin) {
    const route = routeOptions[app]
    if (route) {
      window.location.href = `${origin}${route}${search}`
      return null
    }
  }

  return (
    <Card>
      <h1>{I18n.t('authConfigFailed', trOpt)}</h1>
      <p>{I18n.t('authError', trOpt)}</p>
    </Card>
  )
}

export default GoogleAuthEntryPoint
