import React, { useCallback, useState } from 'react'
import { Modal as _Modal, message, Alert, Button } from 'antd'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import I18n from 'i18n-js'

import MutationForm from '../MutationForm/MutationForm'
import { SEND_POLICIES, GET_POLICIES, GET_LEARNER_POLICIES } from '../Queries/uPolicy'
import { ErrorAlerts } from '../common'
import { invalidatePoliciesQueryCache } from '../../helpers'

const trOpt = { scope: 'modals.sendPolicyLearnerModal' }

const Modal = styled(_Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .ant-modal-title {
    margin-right: 15px;

    h1 {
      margin-bottom: 0;
      margin-top: 12px;
    }
  }
`

const SendPolicyLearnerModalFooter = styled.div`
  display: flex;
`
const SendPolicyLearnerModalFooterAlertColumn = styled.div`
  flex: 1;

  .ant-alert {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
const SendPolicyLearnerModalFooterButtonColumn = styled.div`
  padding-left: 10px;
  text-align: right;
`

const reducePolicyNames = (key, learnerName, policyNames = []) => {
  return I18n.t(key, {
    ...trOpt,
    name: learnerName,
    count: policyNames.length,
    // Assumes list syntax similar to English
    // We could probably do with a helper for this that takes locale as an arg to handle other syntax
    policies: policyNames.reduce((message, name, index, names) => {
      if (names.length > 1 && index === names.length - 1) {
        message += ` ${I18n.t('common.and')}`
      } else if (index > 0) {
        message += ','
      }
      message += ` "${name}"`
      return message
    }, '')
  })
}

export const showSendPoliciesMessages = ({ completed = {}, learnerIds, policyIds = [], policies = [] }) => {
  const learnerCount = learnerIds.length
  policyIds.forEach(policyId => {
    const policy = policies.find(policy => policy.id === `${policyId}`)
    const policyName = policy ? policy.name : I18n.t('unknownPolicy', trOpt)
    const completedCount = completed.find(({ id }) => id === policyId)?.learners.length || 0

    if (completedCount === learnerCount) {
      message.success(I18n.t('successMessage', { ...trOpt, count: completedCount, name: policyName }))
    } else if (completedCount === 0) {
      message.error(I18n.t('noneSentError', { ...trOpt, count: learnerCount, name: policyName }))
    } else {
      message.warning(I18n.t('someSentMessage', { ...trOpt, completedCount, count: learnerCount, name: policyName }))
    }
  })
}

const SendPolicyLearnerModal = ({ learners = [], visible = false, setVisible = () => {}, onClose = () => {} }) => {
  const form = React.createRef()
  const learnerIds = learners.map(learner => learner.id, 10)
  const singleLearner = learnerIds.length === 1
  const learnerName = singleLearner ? learners[0].name : undefined

  const [submitLabel, updateSubmitLabel] = useState(I18n.t('send', trOpt))
  const [sentMessage, updateSentMessage] = useState(null)
  const [signedMessage, updateSignedMessage] = useState(null)
  // Holding Apollo errors in state prevents the nesting of Query components masking errors
  // For example, if GET_POLICIES errors but GET_LEARNER_POLICIES does not then we don't always see the GET_POLICIES error as it becomes undefined
  // TODO Verify if this applies to hooks and refactor if not
  const [learnerPoliciesErr, updateLearnerPoliciesErr] = useState(null)
  const [policiesErr, updatePoliciesErr] = useState(null)

  const closeModal = useCallback(() => {
    setVisible(false)
    if (form && form.current) {
      form.current.resetFields()
    }
    updateSubmitLabel(I18n.t('send', trOpt))
    updateSentMessage(null)
    updateSignedMessage(null)
    updateLearnerPoliciesErr(null)
    updatePoliciesErr(null)
    onClose()
  }, [setVisible, form, onClose])
  const onCancel = useCallback(() => closeModal(), [closeModal])

  const { data: learnerPoliciesData = {} } = useQuery(GET_LEARNER_POLICIES, {
    skip: !(visible && singleLearner),
    variables: { learnerId: learnerIds[0] },
    onError: updateLearnerPoliciesErr
  })
  const { data: policiesData = {} } = useQuery(GET_POLICIES, {
    skip: !visible,
    variables: { basicOnly: true, restrictToPlan: true, withCompany: true },
    onError: updatePoliciesErr
  })

  const { policies = [] } = policiesData
  const policyOptions = policies
  // Sort by name
    .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase())
    .map(policy => ({ value: policy.id, label: policy.name }))

  const variables = {
    learnerIds
  }
  const fields = [
    {
      id: 'policyIds',
      label: I18n.t('uPolicy.common.policy'),
      type: 'multiSelect',
      required: true,
      placeholder: I18n.t('selectPolicies', trOpt),
      options: policyOptions,
      mutateValue: values => values || []
    },
    {
      id: 'skipEmails',
      type: 'checkbox',
      label: I18n.t('uPolicy.common.skipNotificationEmail'),
      renderLabelInline: true,
      value: skipEmails => skipEmails || false
    }
  ]

  const updateMessagesAndLabel = useCallback((selectedPolicies) => {
    let submitLabel = I18n.t('send', trOpt)
    let sentMessage
    let signedMessage
    if (singleLearner && selectedPolicies.length > 0) {
      const { learner: { policyResults: learnerPolicies = [] } = {} } = learnerPoliciesData
      const sentPolicies = learnerPolicies.filter(l => l.signed === null).map(l => l.policyId)
      const signedPolicies = learnerPolicies.filter(l => l.signed !== null).map(l => l.policyId)

      const sentPolicyNames = []
      const signedPolicyNames = []
      selectedPolicies.forEach(policyId => {
        const policy = policies.find(c => `${c.id}` === policyId)
        if (signedPolicies.includes(policyId)) {
          signedPolicyNames.push(policy.name)
        } else if (sentPolicies.includes(policyId)) {
          sentPolicyNames.push(policy.name)
        }
      })

      const allSent = sentPolicyNames.length === selectedPolicies.length
      if (sentPolicyNames.length > 0) {
        sentMessage = reducePolicyNames('reminderMessage', learnerName, sentPolicyNames)
      }
      const allSigned = signedPolicyNames.length === selectedPolicies.length
      if (signedPolicyNames.length > 0) {
        signedMessage = reducePolicyNames('resentMessage', learnerName, signedPolicyNames)
      }

      if (allSent) {
        submitLabel = I18n.t('common.sendReminder')
      } else if (allSigned) {
        submitLabel = I18n.t('sendAgain', trOpt)
      }
    }
    updateSubmitLabel(submitLabel)
    updateSentMessage(sentMessage)
    updateSignedMessage(signedMessage)
  }, [learnerName, learnerPoliciesData, policies, singleLearner])

  const onChange = useCallback((name, values) => {
    if (name === 'policyIds') {
      updateMessagesAndLabel(values)
    }
  }, [updateMessagesAndLabel])

  const onSuccess = useCallback(({ data: { sendPoliciesToLearners: { success = false, completed = [], policyIds = [], learnerIds = [] } = {} } = {} }) => {
    showSendPoliciesMessages({ completed, learnerIds, policyIds, policies })

    if (success) {
      closeModal()
    }
  }, [closeModal, policies])

  const renderFooter = useCallback(({ submitLabel, loading }) => (
    <SendPolicyLearnerModalFooter>
      <SendPolicyLearnerModalFooterAlertColumn>
        {sentMessage ? <Alert message={sentMessage} /> : null}
        {signedMessage ? <Alert message={signedMessage} /> : null}
      </SendPolicyLearnerModalFooterAlertColumn>
      <SendPolicyLearnerModalFooterButtonColumn>
        <Button type='primary' ghost disabled={loading} htmlType='submit'>{submitLabel}</Button>
      </SendPolicyLearnerModalFooterButtonColumn>
    </SendPolicyLearnerModalFooter>
  ), [sentMessage, signedMessage])

  let title
  if (singleLearner && learnerName) {
    title = I18n.t('titleUserName', { ...trOpt, name: learnerName })
  } else {
    title = I18n.t('title', { ...trOpt, count: learnerIds.length })
  }

  return (
    <Modal
      visible={visible} onCancel={onCancel} footer={null}
      title={<h1>{title}</h1>}
      width='600px'
    >
      <ErrorAlerts error={[learnerPoliciesErr, policiesErr]} />
      <MutationForm
        mutation={SEND_POLICIES}
        onChange={onChange}
        onSuccess={onSuccess}
        failureMessage={I18n.t('errorMessage', trOpt)}
        submitLabel={submitLabel} variables={variables}
        disableSubmitIfInvalid={false}
        fields={fields}
        ref={form}
        footer={renderFooter}
        update={invalidatePoliciesQueryCache}
      />
    </Modal>
  )
}

export default SendPolicyLearnerModal
