import React from 'react'
import { Modal, message, Table } from 'antd'
import I18n from 'i18n-js'

import authenticatedClient from '../../apollo-client/authenticatedClient'
import { RESTORE_DELETED_LEARNERS } from '../Queries/Learners'
import { invalidateLearnersQueryCache, showErrors } from '../../helpers'
import { CopyToClipboard } from '../Settings/common'

const trOpt = { scope: 'modals.restoreUsersConfimModal' }

const ErrorTable = ({ errors }) => {
  const renderClipboardCell = (learnerId, learner) => (
    <CopyToClipboard
      value={learnerId}
      label={I18n.t('copyLearnerId', { ...trOpt })}
      copiedMessage={I18n.t('learnerIdCopied', { ...trOpt })}
    />
  )

  const data = errors.map(error => ({
    ...error,
    key: `${error.learnerId}-${error.errorCode}`,
    errorText: error.errorCodes.map(({ errorCode }) => errorCode ? I18n.t(`errors.${errorCode}`, { ...trOpt, defaults: [{ message: errorCode }] }) : I18n.t('errors.exception', { ...trOpt })).join('; ')
  }))
  const columns = [
    {
      get title () { return I18n.t('learners.common.emailUserId') },
      dataIndex: 'emailUserId',
      key: 'emailUserId'
    },
    {
      get title () { return I18n.t('errorColumnHeading', { ...trOpt }) },
      dataIndex: 'errorText',
      key: 'errorText'
    },
    {
      title: '',
      dataIndex: 'learnerId',
      align: 'right',
      render: renderClipboardCell
    }
  ]
  return <Table {...{ columns }} dataSource={data} />
}

const restoreUsersConfirm = async (learnerIds = [], { refetchQueries, client = authenticatedClient, onClose = () => {} } = {}) => {
  Modal.confirm({
    title: I18n.t('title', { ...trOpt, count: learnerIds.length }),
    okText: I18n.t('common.yes'),
    cancelText: I18n.t('common.no'),
    async onOk () {
      try {
        const result = await client.mutate({
          mutation: RESTORE_DELETED_LEARNERS,
          variables: { learnerIds },
          refetchQueries,
          update: invalidateLearnersQueryCache
        })

        const { data: { restoreDeletedLearners: { success, errors } } } = result

        if (errors.length > 0) {
          Modal.error({
            title: I18n.t('errorTableTitle', { ...trOpt, count: errors.length }),
            content: <ErrorTable errors={errors} />,
            width: '60%'
          })
        }
        if (success.length > 0) {
          message.success(I18n.t('successMessage', { ...trOpt, count: success.length }))
        }
      } catch (e) {
        showErrors(e, I18n.t('errorMessage', trOpt))
      } finally {
        onClose()
      }
    },
    onCancel: onClose
  })
}

export default restoreUsersConfirm
