import React, { useEffect } from 'react'
import { Router, Route, Redirect, Switch } from 'react-router-dom'
import styled, { ThemeProvider, withTheme } from 'styled-components'
import { message } from 'antd'
import 'antd/dist/antd.css'
import { Helmet } from 'react-helmet'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { compose } from 'recompose'
import _isNil from 'lodash/isNil'
import * as reactQuery from '@tanstack/react-query'

import '../translations'
import routes, { redirects } from '../constants/routes'
import history from '../constants/history'
import { GlobalStyle, themes, buildTheme } from '../theme'
import { connect, withAppTitleAndIcons } from '../hocs'
import { LoadingBlock, AntdConfigProvider } from '../components/common'
import viewSelectors, { viewLoading } from '../state/selectors/view'
import { getSettings } from '../state/selectors/settings'
import SessionLinkedSwitch from './SessionLinkedSwitch'
import LearnerCourse from './LearnerCourse'
import { ExternalAccessPolicies, LearnerPolicy } from './uPolicy'
import { RiskReport } from './Reports'
import { DemoLocaleListener } from '../components/common/LanguageDropdown'
import { useFavicon, useManifest, useUpdateSentryUser } from '../hooks'
import EndUserSessionSwitcher from './EndUserSessionSwitcher'
import PerformanceReportContentView from './Reports/PerformanceReport/PerformanceReportContentView'
import DomainScanReportContentView from './Reports/uBreach/DomainScanReportContentView'
import BreachReportContentView from './Reports/BreachReport/BreachReportContentView'
import DownloadReport from './Reports/DownloadReport'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import posthog from 'posthog-js'
import PageNotFound from './PageNotFound'

// Lower antd messages to avoid obstruct by intercom banners
message.config({ top: 90 })

const AppContainer = styled.div`
  height: 100%;
  width: 100%;
`

const SettingsThemeProvider = ({ settings, children }) => (
  <ThemeProvider theme={buildTheme(themes.main, settings)}>{children}</ThemeProvider>
)
const ConnectedThemeProvider = connect(state => ({ settings: getSettings(state) }))(SettingsThemeProvider)
const ConnectedLoadingBlock = connect(viewLoading)(LoadingBlock)

const AppHead = compose(
  connect(viewSelectors.titleAndIcons),
  withTheme
)(
  ({ title: pageTitle, appIcon, favicon16, favicon32, favicon180, safariTabIcon, theme }) => {
    const [appFaviconLink, setAppFavicon] = useFavicon({ rel: 'icon', skipType: true, id: 'appIcon' })
    const [favicon16Link, setFavicon16] = useFavicon({ rel: 'icon', sizes: '16x16', id: 'favicon16' })
    const [favicon32Link, setFavicon32] = useFavicon({ rel: 'icon', sizes: '32x32', id: 'favicon32' })
    const [appleTouchIconLink, setAppleTouchIcon] = useFavicon({ rel: 'apple-touch-icon', id: 'appleTouchIcon' })
    const [safariTabIconLink, setSafariTabIcon] = useFavicon({ rel: 'mask-icon', color: theme.primary, id: 'safariTabIcon' })
    const [manifestHref, setManifest] = useManifest()

    useEffect(() => setAppFavicon(appIcon), [appIcon, setAppFavicon])
    useEffect(() => setFavicon16(appIcon ? null : favicon16), [appIcon, favicon16, setFavicon16])
    useEffect(() => setFavicon32(appIcon ? null : favicon32), [appIcon, favicon32, setFavicon32])
    useEffect(() => setAppleTouchIcon(appIcon || favicon180), [appIcon, favicon180, setAppleTouchIcon])
    useEffect(() => setSafariTabIcon(appIcon || safariTabIcon, { color: theme.primary }), [appIcon, safariTabIcon, setSafariTabIcon, theme])
    useEffect(() => setManifest({ useDefaultIcons: _isNil(appIcon), pageTitle, icon: appIcon, backgroundColor: theme.primary, themeColor: theme.secondary }), [setManifest, pageTitle, appIcon, theme])

    return (
      <Helmet>
        <title>{pageTitle}</title>
        <link rel='manifest' href={manifestHref} />
        {appFaviconLink}
        {favicon16Link}
        {favicon32Link}
        {appleTouchIconLink}
        {safariTabIconLink}
        <meta name='description' content={pageTitle} />
        <meta name='application-name' content={pageTitle} />
        <meta name='msapplication-TileColor' content={theme.primary} />
        <meta name='theme-color' content={theme.primary} />
      </Helmet>
    )
  }
)

const redirectComps = redirects.map((route, index) => (
  <Redirect key={`routeGroupId_${index}`} from={route.from} to={route.to} />
))

function PageViewTracker () {
  const location = useLocation()

  useEffect(() => {
    posthog.capture('$pageview')
  }, [location])

  return null
}

const App = () => {
  // Handle plain html links to app pages with React Router - allows system links to be embedded in i18n translations
  useEffect(() => {
    const onBodyClick = e => {
      const { target } = e || {}
      if (target && target.classList.contains('usecure-manual-rr-link')) {
        e.preventDefault()
        if (target.href) {
          history.push(target.href.replace(window.location.origin, ''))
        }
      }
    }
    document.addEventListener('click', onBodyClick)

    return () => {
      document.removeEventListener('click', onBodyClick)
    }
  }, [])

  // Updates sentry user based on global state - for logging purposes
  useUpdateSentryUser()

  return (
    <ConnectedThemeProvider>
      <>
        <AppHead />
        <GlobalStyle />
        <ConnectedLoadingBlock />
        <DemoLocaleListener />
        <reactQuery.QueryClientProvider client={new reactQuery.QueryClient()}>
          <Router history={history}>
            <PageViewTracker />
            <AntdConfigProvider>
              <AppContainer>
                <Switch>
                  <Route
                    exact
                    path={routes.LEARNER_COURSE}
                    component={LearnerCourse}
                  />
                  <Route
                    exact
                    path={routes.LEARNER_POLICY}
                    component={LearnerPolicy}
                  />
                  <Route
                    exact
                    path={[routes.EXTERNAL_POLICIES, routes.EXTERNAL_POLICY]}
                    component={ExternalAccessPolicies}
                  />
                  <Route
                    exact
                    path={routes.RISK_REPORT}
                    component={RiskReport}
                  />
                  <Route
                    exact
                    path={routes.PERFORMANCE_REPORT_CONTENT_VIEW}
                    component={PerformanceReportContentView}
                  />
                  <Route
                    exact
                    path={routes.BREACH_REPORT_CONTENT_VIEW}
                    component={BreachReportContentView}
                  />
                  <Route
                    exact
                    path={routes.DOMAIN_SCAN_REPORT_CONTENT_VIEW}
                    component={DomainScanReportContentView}
                  />
                  <Route
                    exact
                    path={routes.DOWNLOAD_REPORT}
                    component={DownloadReport}
                  />
                  <Route
                    exact
                    path={routes.SESSION_LINKED}
                    component={SessionLinkedSwitch}
                  />
                  <Route
                    exact
                    path={routes.PORTAL}
                    component={EndUserSessionSwitcher}
                  />
                  {redirectComps}
                  <Route component={PageNotFound} />
                </Switch>
              </AppContainer>
            </AntdConfigProvider>
          </Router>
        </reactQuery.QueryClientProvider>
      </>
    </ConnectedThemeProvider>
  )
}

export default withAppTitleAndIcons()(App)
