import React, { useState, useCallback, useEffect } from 'react'
import { Card, Button } from 'antd'
import queryString from 'query-string'
import styled from 'styled-components'
import { useMutation } from '@apollo/react-hooks'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isString from 'lodash/isString'

import routes from '../../constants/routes'
import { AUTH_REDIRECT_URL_ORIGIN } from '../../constants/environment'
import { Office365, SYNC_DEFAULT_SCOPES } from '../../helpers'
import { UPDATE_M365_AUTH } from '../../components/Queries/Companies'
import { LoadingBlock } from '../../components/common'
import { Link } from 'react-router-dom'

const REDIRECT_URI = `${window.location.origin}/ms-auth-test` // Fallback redirect URI for in progress features

const ContentWrap = styled.div`
  height: 100%;
  padding: 1rem 50px 5.809rem;
`

const ContentCard = styled(Card)`
  height: 100%;
  .ant-card-body {
    height: 100%;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const BodyContainer = styled.div`
  flex-grow: 1;
  overflow: auto;

  p {
    font-family: unset;
    font-size: 16px;
  }
`

const AuthTypeContainer = styled.div`
  margin-bottom: 20px;
`

const AuthButtonContainer = styled.div`
  max-width: 250px;
  width: 100%;
  .ant-btn {
    margin-bottom: 5px;
  }
`

// Add a new AD App to provide a quick auth flow for it
// Useful for building a new integration ahead of the final UI work
const M365_AUTH_APPS = [{
  clientId: window.__USECURE_CONFIG__.REACT_APP_OFFICE_365_CLIENT_ID,
  app: 'sync',
  title: 'Microsoft 365 Sync',
  redirectUri: `${AUTH_REDIRECT_URL_ORIGIN}${routes.OFFICE_365_AUTH_ENTRY_POINT}`, // Override redirect URI to use existing post auth page
  delegated: true,
  scopes: SYNC_DEFAULT_SCOPES
}, {
  clientId: window.__USECURE_CONFIG__.REACT_APP_PHISH_ALERT_CLIENT_ID,
  app: 'phishAlertMailbox',
  title: 'Mailbox Email Forwarding',
  redirectUri: `${AUTH_REDIRECT_URL_ORIGIN}${routes.PHISH_ALERT_M365_AUTH_ENTRY_POINT}`
}, {
  clientId: window.__USECURE_CONFIG__.REACT_APP_PHISH_ALERT_SSO_CLIENT_ID,
  app: 'phishAlertSSO',
  title: 'SSO Email Forwarding',
  redirectUri: `${AUTH_REDIRECT_URL_ORIGIN}${routes.PHISH_ALERT_M365_AUTH_ENTRY_POINT}`
}, {
  clientId: window.__USECURE_CONFIG__.REACT_APP_M365_MESSAGE_INJECTION_CLIENT_ID,
  app: 'messageInjection',
  title: 'Message Injection',
  redirectUri: `${AUTH_REDIRECT_URL_ORIGIN}${routes.MESSAGE_INJECTION_M365_AUTH_ENTRY_POINT}`
}].filter(({ clientId }) => _isString(clientId) && !_isEmpty(clientId))

const ADMIN_TYPES = M365_AUTH_APPS.filter(({ adminConsent = true }) => adminConsent === true)
const DELEGATED_TYPES = M365_AUTH_APPS.filter(({ delegated }) => delegated === true)

const StartAuthButton = ({ title, app, clientId, redirectUri, useDelegated = false, scopes, onClick: onClickProp = () => { } }) => {
  const onClick = useCallback(() => onClickProp({ clientId, app, redirectUri, useDelegated, scopes }), [app, clientId, redirectUri, useDelegated, scopes, onClickProp])
  return (
    <Button type='primary' block onClick={onClick}>Start {title} Auth</Button>
  )
}

const GoBackButton = () => (
  <Link to={routes.M365_AUTH_TEST_START}>
    <Button type='primary'>Go Back</Button>
  </Link>
)

const M365AuthTest = ({ match, history }) => {
  const [status, setStatus] = useState('loading')
  const [authTypeTitle, setAuthTypeTitle] = useState(null)
  const [executeAuthUpdate] = useMutation(UPDATE_M365_AUTH)
  const updateAuth = useCallback(async ({ app, tenantId, authType, authCode }) => {
    try {
      await executeAuthUpdate({
        variables: {
          app,
          authType,
          tenantId,
          authCode,
          redirectUri: (
            authCode
              ? ((M365_AUTH_APPS.find(type => type.app === app) || {}).redirectUri || REDIRECT_URI)
              : null
          )
        }
      })
      setStatus('complete')
    } catch (e) {
      console.error('PhishAlertAuthM365 ERROR', e)
      setStatus('error')
    }
  }, [executeAuthUpdate])
  useEffect(() => {
    const isStart = _get(match, 'path') === routes.M365_AUTH_TEST_START
    if (isStart) {
      setStatus('start')
    } else if (status === 'loading') {
      setStatus('updating')
      const parsedQueryString = queryString.parse(window.location.search)
      const { admin_consent: adminConsent, tenant: tenantId, state: app, code: authCode } = parsedQueryString
      setAuthTypeTitle((M365_AUTH_APPS.find(type => type.app === app) || {}).title || '[NO AUTH TYPE]')
      history.push(routes.M365_AUTH_TEST)
      if (app && authCode) {
        updateAuth({ app, authType: 'delegated', authCode })
      } else if (app && tenantId && adminConsent === 'True') {
        updateAuth({ app, authType: 'admin', tenantId })
      } else {
        setStatus('error')
      }
    }
  }, [match, history, status, updateAuth])

  const startAuth = useCallback(({ clientId, app, redirectUri = REDIRECT_URI, scopes, useDelegated = false }) => {
    // Navigate to admin consent URL
    const office365 = new Office365({
      clientId,
      redirectUri,
      scopes
    })
    window.location.href = useDelegated ? office365.getAuthorizeUrl(app) : office365.getAdminConsentUrl(app)
  }, [])

  return (
    <ContentWrap>
      <LoadingBlock loading={status === 'loading' || status === 'updating'} />
      <ContentCard>
        <ContentContainer>
          <h1>Microsoft 365 Auth Test Page</h1>
          <BodyContainer>
            {status === 'start' && (
              <>
                <AuthTypeContainer>
                  <h2>Application Permissions via Admin Consent</h2>
                  {ADMIN_TYPES.length > 0 ? (
                    <AuthButtonContainer>
                      {ADMIN_TYPES.map((app, index) => <StartAuthButton key={index} onClick={startAuth} {...app} />)}
                    </AuthButtonContainer>
                  ) : <p>None Configured</p>}
                </AuthTypeContainer>
                <AuthTypeContainer>
                  <h2>Delegated Permissions</h2>
                  {DELEGATED_TYPES.length > 0 ? (
                    <AuthButtonContainer>
                      {DELEGATED_TYPES.map((app, index) => <StartAuthButton key={index} onClick={startAuth} useDelegated {...app} />)}
                    </AuthButtonContainer>
                  ) : <p>None Configured</p>}
                </AuthTypeContainer>
              </>
            )}
            {status === 'complete' && (
              <>
                <h1>{authTypeTitle} Auth Complete</h1>
                <GoBackButton />
              </>
            )}
            {status === 'error' && (
              <>
                <h1>{authTypeTitle} Auth Failed</h1>
                <GoBackButton />
              </>
            )}
          </BodyContainer>
        </ContentContainer>
      </ContentCard>
    </ContentWrap>
  )
}

export default M365AuthTest
