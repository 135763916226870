import React, { useEffect } from 'react'
import { Card } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import { creators as viewActions } from '../state/actions/view'
import { connect } from '../hocs'

const Container = styled.div`
  text-align: center;
`

const PageNotFound = ({ setLoadingVisible }) => {
  useEffect(() => {
    setLoadingVisible(false)
  }, [setLoadingVisible])

  return (
    <Card>
      <Container>
        <h1>{I18n.t('common.pageNotFound')}</h1>
      </Container>
    </Card>
  )
}

export default connect(
  undefined,
  dispatch => ({
    setLoadingVisible: loading => dispatch(viewActions.loading(loading))
  })
)(PageNotFound)
