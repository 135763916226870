import gql from 'graphql-tag'

export const GET_COMPANY = gql`
    query($id: String) {
        company(id: $id) {
            id
            name
            plan {
              valid
            }
        }
    }
`

export const GET_SIMPLE_COMPANY = gql`
    query($id: String, $withParent: Boolean = false, $withEndUserAuthOptions: Boolean = false) {
        simpleCompany(id: $id) {
            id
            name
            planName
            planValid
            accountType
            settings
            uBreachProEnabled
            parentCompany @include(if: $withParent) {
              id
              name
              uBreachProEnabled
            }
            endUserAuthOptions @include(if: $withEndUserAuthOptions) {
              magicLink
              saml
              samlSharedMSP
              google
              microsoft
            }
        }
    }
`

export const GET_COMPANIES = gql`
    query (
      $descendants: Boolean, $ancestorCompanyId: ID,
      $withAccountType: Boolean = false, $withParentCompanyId: Boolean = false, $withPlanValid: Boolean = false
    ) {
        companies (all: true, descendants: $descendants, ancestorCompanyId: $ancestorCompanyId) {
            id
            name
            accountType @include(if: $withAccountType)
            parentCompanyId @include(if: $withParentCompanyId)
            plan @include(if: $withPlanValid) {
              valid
            }
        }
    }
`
export const GET_COMPANIES_BY_ACCOUNT_TYPE = gql`
  query(
    $accountType: AccountType, $all: Boolean,
    $withAccountType: Boolean = false, $withParentCompanyId: Boolean = false, $withPlanValid: Boolean = false, $withSettings: Boolean = false, $withUBreachProEnabled: Boolean = false
  ) {
    companies(accountType: $accountType, all: $all) {
      id
      name
      accountType @include(if: $withAccountType)
      parentCompanyId @include(if: $withParentCompanyId)
      plan @include(if: $withPlanValid) {
        valid
      }
      settings @include(if: $withSettings)
      uBreachProEnabled @include(if: $withUBreachProEnabled)
    }
  }
`

const USERVICE_DATA_FIELDS = `
id
name
suspended
accountType
plan {
  learnerLimit
  domainLock
  planName
  expires
  expiryDate
}
partner
parentCompanyId
parentCompany{
  name
  uBreachProEnabled
  parentCompany {
    id
    name
  }
}
childCompanyCount
activeLearnerCount
billingType
domain
externalId
riskReport {
  status
  email
  firstName
  lastName
  lastActivity
  signUp
}
locale
timezone
uServiceSettings
createdAt
region
`

const USERVICE_UPDATE_FIELDS = `
${USERVICE_DATA_FIELDS}
contentLocales
settings
billingSettings {
  startDate
  stripeCustomerId
  billingEmail
  currency
  taxRate
  billableUsers
  exemptFromDistributorBilling
  showAddressOnInvoice
  invoiceCompanyName
  addressLine1
  addressLine2
  addressLine3
  addressLine4
  addressPostcode
  addressCountry
}
`

export const GET_USERVICE_DATA = gql`
    query($accountType: AccountType, $all: Boolean, $offset: Int, $limit: Int) {
        companies(accountType: $accountType, all: $all, offset: $offset, limit: $limit) {
            ${USERVICE_DATA_FIELDS}
        }
    }
`

export const GET_USERVICE_COUNT = gql`
    query($accountType: AccountType, $all: Boolean) {
      companiesCount(accountType: $accountType, all: $all)
    }
`

export const GET_USERVICE_PRIORITY_TASKS = gql`
  query($accountType: AccountType, $offset: Int, $limit: Int) {
    uServiceCompanyRiskReportTasks(accountType: $accountType, offset: $offset, limit: $limit) {
      ${USERVICE_DATA_FIELDS}
    }
  }
`

export const GET_USERVICE_QUICK_SEARCH = gql`
  query($searchTerm: String!, $accountType: AccountType, $limit: Int) {
    uServiceCompanyQuickSearch(searchTerm: $searchTerm, accountType: $accountType, limit: $limit) {
      ${USERVICE_DATA_FIELDS}
    }
  }
`

export const USERVICE_INVOICES = gql`
  query {
    uServiceInvoices {
      periods {
        startDate
        endDate
      }
      companiesMap
      invoicePeriods
    }
  }
`

export const USERVICE_INVOICES_DISTRI = gql`
    query {
        uServiceInvoices {
            periods {
                startDate
                endDate
            }
            companiesMap
            invoicePeriodsDistributor
        }
    }
`

export const USERVICE_INVOICE_DATES = gql`
  query {
    uServiceInvoices {
      periods {
        startDate
        endDate
      }
    }
  }
`

export const GET_COMPANY_BILLING = gql`
  query {
    company {
      uBreachProPricing {
        currency
        tiers {
          upTo
          flatAmount
        }
      }
    }
  }
`

export const GET_COMPANY_USAGE = gql`
    query {
        company {
          name
          billingSettings {
            startDate
            stripeCustomerId
            billingEmail
            currency
            taxRate
          }
          usage {
            startDate
            cost
            currency
            updatedAt
            distributorId
          }
        }
    }
`

export const GET_COMPANY_FOR_INVOICE = gql`
    query {
        company {
          name
          billingSettings {
            startDate
            stripeCustomerId
            billingEmail
            currency
            taxRate
            showAddressOnInvoice
            invoiceCompanyName
            addressLine1
            addressLine2
            addressLine3
            addressLine4
            addressPostcode
            addressCountry
          }
        }
    }
`

export const GET_COMPANY_FOR_UPGRADE_TRIAL = gql`
    query($id: String) {
      company(id: $id) {
            id
            name
            settings
            parentCompanyId
            plan {
              domainLock
            }
        }
    }
`

export const GET_COMPANY_SETTINGS = gql`
    query {
        company {
            id
            name
            settings
        }
    }
`

export const GET_PLAN_DETAILS = gql`
  query {
    plan {
      id
      currentLearners
      learnerLimit
      domainLock
      planName
      expires
    }
  }
`

const SETTINGS_RELOAD_FIELDS = `
settings
parentDefaultSettings
accountType
editExternalId
disableTenantCreation
disableTenantDeletion
disableProspectCreation
disableProspectDeletion
enableProspects
enableRiskReportTenants
phishAlertEnabled
helpCentreEnabled
uBreachProEnabled
intercomEnabled
enableCreateDomainScan
riskReportUPhishCompletionType
locale
timezone
riskReport {
  status
  simulationId
}
learnerAutoEnrolExcludeEnabled
appProductNames {
  uLearn
  uPhish
  uBreach
  uBreachPro
  uPolicy
}
`

export const SETTINGS_SUBSCRIPTION = gql`
  subscription onSettingsUpdated {
    settingsUpdated {
      ${SETTINGS_RELOAD_FIELDS}
    }
  }
`

export const UPDATE_COMPANY_SETTING = gql`
  mutation($settingId: String!, $setting: JSON) {
    updateCompanySetting(settingId: $settingId, setting: $setting) {
      id
      name
      ${SETTINGS_RELOAD_FIELDS}
    }
  }
`

export const UPDATE_USERVICE_COMPANY_SETTING = gql`
  mutation($companyId: ID!, $settingId: String!, $setting: JSON) {
    updateUServiceCompanySetting(companyId: $companyId, settingId: $settingId, setting: $setting) {
      id
      name
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const UPDATE_COMPANY_EMAIL_SETTING = gql`
  mutation($settingId: String!, $setting: JSON) {
    updateCompanyEmailSetting(settingId: $settingId, setting: $setting) {
      id
      name
      ${SETTINGS_RELOAD_FIELDS}
    }
  }
`

export const UPDATE_COMPANY_SETTINGS = gql`
  mutation($settings: JSON) {
    updateCompanySettings(settings: $settings) {
      id
      name
      ${SETTINGS_RELOAD_FIELDS}
    }
  }
`

export const UPDATE_PHISH_ALERT_SETTINGS = gql`
  mutation($settings: JSON) {
    updateCompanyPhishAlertSettings(settings: $settings) {
      id
      name
      ${SETTINGS_RELOAD_FIELDS}
    }
  }
`

export const UPDATE_DEFAULT_TENANT_PHISH_ALERT_SETTINGS = gql`
  mutation($settings: JSON) {
    updateDefaultTenantPhishAlertSettings(settings: $settings) {
      id
      name
      ${SETTINGS_RELOAD_FIELDS}
    }
  }
`

export const UPDATE_DEFAULT_TENANT_SETTING = gql`
  mutation($settingId: String!, $setting: JSON) {
    updateDefaultTenantSetting(settingId: $settingId, setting: $setting) {
      id
      name
      ${SETTINGS_RELOAD_FIELDS}
    }
  }
`

export const UPDATE_DEFAULT_TENANT_EMAIL_SETTING = gql`
  mutation($settingId: String!, $setting: JSON) {
    updateDefaultTenantEmailSetting(settingId: $settingId, setting: $setting) {
      id
      name
      ${SETTINGS_RELOAD_FIELDS}
    }
  }
`

export const UPDATE_DEFAULT_TENANT_SETTINGS = gql`
  mutation($settings: JSON) {
    updateDefaultTenantSettings(settings: $settings) {
      id
      name
      ${SETTINGS_RELOAD_FIELDS}
    }
  }
`
export const UPDATE_UBREACH_PRO_SETTING = gql`
  mutation($setting: JSON) {
    updateUBreachProSetting(setting: $setting) {
      id
      name
      ${SETTINGS_RELOAD_FIELDS}
    }
  }
`

export const UPDATE_AUTO_ENROL_SETTINGS = gql`
  mutation($settings: JSON) {
    updateCompanyAutoEnrolSettings(settings: $settings) {
      id
      name
      ${SETTINGS_RELOAD_FIELDS}
    }
  }
`

export const UPDATE_AUTO_ENROL_DEFAULT_TENANT_SETTINGS = gql`
  mutation($settings: JSON) {
    updateDefaultTenantAutoEnrolSettings(settings: $settings) {
      id
      name
      ${SETTINGS_RELOAD_FIELDS}
    }
  }
`

export const GET_COMPANY_FOR_UPDATE = gql`
    query($id: String) {
        company(id: $id) {
          ${USERVICE_UPDATE_FIELDS}
        }
    }
`

export const CREATE_DISTRIBUTOR = gql`
  mutation($name:String!, $domain: String, $adminUser: AdminUser, $billingType: BillingType, $settings: PartnerSettings!, $billingSettings: DistributorBillingSettings, $locale: LanguageCode, $contentLocales: [LanguageCode!], $timezone: String) {
    createDistributor(name:$name, domain: $domain, adminUser: $adminUser, billingType: $billingType, settings: $settings, billingSettings: $billingSettings, locale: $locale, contentLocales: $contentLocales, timezone: $timezone) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const EDIT_DISTRIBUTOR = gql`
  mutation($companyId: ID!, $name:String!, $domain: String, $billingType: BillingType, $settings: PartnerSettings!, $billingSettings: DistributorBillingSettings, $locale: LanguageCode, $contentLocales: [LanguageCode!], $timezone: String) {
    updateDistributor(companyId: $companyId, name:$name, domain: $domain, billingType: $billingType, settings: $settings, billingSettings: $billingSettings, locale: $locale, contentLocales: $contentLocales, timezone: $timezone) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const CREATE_MSP = gql`
  mutation($parentCompanyId: ID, $name:String!, $domain: String, $billingType: BillingType, $adminUser: AdminUser, $settings: PartnerSettings!, $defaultTenantSettings: DefaultTenantSettingsMSP!, $billingSettings: BillingSettings, $externalId: String, $locale: LanguageCode, $contentLocales: [LanguageCode!], $timezone: String, $region: Region) {
    createMSP(parentCompanyId: $parentCompanyId, name:$name, domain: $domain, billingType: $billingType, adminUser: $adminUser, settings: $settings, defaultTenantSettings: $defaultTenantSettings, billingSettings: $billingSettings, externalId: $externalId, locale: $locale, contentLocales: $contentLocales, timezone: $timezone, region: $region) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const EDIT_MSP = gql`
  mutation($companyId: ID!, $name:String!, $domain: String, $suspended: Boolean, $billingType: BillingType, $settings: PartnerSettings!, $defaultTenantSettings: DefaultTenantSettingsMSP!, $billingSettings: BillingSettings, $externalId: String, $locale: LanguageCode, $contentLocales: [LanguageCode!], $timezone: String, $region: Region) {
    updateMSP(companyId: $companyId, name:$name, domain: $domain, suspended: $suspended, billingType: $billingType, settings: $settings, defaultTenantSettings: $defaultTenantSettings, billingSettings: $billingSettings, externalId: $externalId, locale: $locale, contentLocales: $contentLocales, timezone: $timezone, region: $region) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const CREATE_TENANT = gql`
  mutation($parentCompanyId: ID, $name:String!, $domain: String, $adminUser: AdminUser, $settings: TenantSettings!, $externalId: String, $locale: LanguageCode, $contentLocales: [LanguageCode!], $timezone: String) {
    createTenant(parentCompanyId: $parentCompanyId, name:$name, domain: $domain, adminUser: $adminUser, settings: $settings, externalId: $externalId, locale: $locale, contentLocales: $contentLocales, timezone: $timezone) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const EDIT_TENANT = gql`
  mutation($companyId: ID!, $name:String!, $domain: String, $suspended: Boolean, $settings: TenantSettings!, $billingSettings: BillingSettings, $externalId: String, $locale: LanguageCode, $contentLocales: [LanguageCode!], $timezone: String) {
    updateTenant(companyId: $companyId, name:$name, domain: $domain, settings: $settings, billingSettings: $billingSettings, suspended: $suspended, externalId: $externalId, locale: $locale, contentLocales: $contentLocales, timezone: $timezone) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const CREATE_PROSPECT = gql`
  mutation($parentCompanyId: ID, $name:String!, $domain: String!, $firstName: String, $lastName: String, $email: String!, $externalId: String, $locale: LanguageCode, $contentLocales: [LanguageCode!], $startReport: Boolean!, $timezone: String) {
    createProspect(parentCompanyId: $parentCompanyId, name:$name, domain: $domain, firstName: $firstName, lastName: $lastName, email: $email, externalId: $externalId, locale: $locale, contentLocales: $contentLocales, startReport: $startReport, timezone: $timezone) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`
export const EDIT_PROSPECT = gql`
  mutation($companyId: ID!, $name:String!, $domain: String!, $firstName: String, $lastName: String, $email: String!, $externalId: String, $locale: LanguageCode, $contentLocales: [LanguageCode!], $timezone: String) {
    updateProspect(companyId: $companyId, name:$name, domain: $domain, firstName: $firstName, lastName: $lastName, email: $email, externalId: $externalId, locale: $locale, contentLocales: $contentLocales, timezone: $timezone) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const UPGRADE_PROSPECT_TO_PAID_TENANT = gql`
  mutation($companyId: ID!, $name:String!, $domain: String, $settings: TenantSettings!, $billingSettings: BillingSettings, $externalId: String, $locale: LanguageCode, $contentLocales: [LanguageCode!]) {
    upgradeProspectToPaidTenant(companyId: $companyId, name:$name, domain: $domain, settings: $settings, billingSettings: $billingSettings, externalId: $externalId, locale: $locale, contentLocales: $contentLocales) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const RECORD_PROSPECT_UPGRADE_CANCELLATION = gql`
  mutation($companyId: ID!, $eventType: ProspectUpgradeCancellationType!) {
    recordProspectUpgradeCancellation(companyId: $companyId, eventType: $eventType) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const EXTEND_TRIAL = gql`
  mutation($companyId: ID!) {
    extendTrial(companyId: $companyId) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const DELETE_COMPANIES = gql`
  mutation($companyIds: [ID!]!) {
    deleteCompanies(companyIds: $companyIds)
  }
`

export const REPROCESS_OUTSTANDING_INVOICES = gql`
  mutation($companyId: ID!) {
    reprocessOutstandingInvoices(companyId: $companyId)
  }
`

export const CREATE_STRIPE_SETUP_INTENT = gql`
  mutation($confirmationTokenId: String!) {
    createStripeSetupIntent(confirmationTokenId: $confirmationTokenId) {
      id
      status
      clientSecret
    }
  }
`

export const CREATE_STRIPE_CUSTOMER_PORTAL_SESSION = gql`
  mutation($returnUrl: String!) {
    createStripeCustomerPortalSession(returnUrl: $returnUrl) {
      url
    }
  }
`

export const GET_PAYMENT_DETAILS = gql`
  query {
    paymentDetails {
      defaultPaymentMethod {
          brand
          last4
          expiryDate
      }
      currency
    }
  }
`

export const UPDATE_M365_AUTH = gql`
  mutation($app: M365App!, $authType: M365AuthType!, $tenantId: ID, $authCode: String, $redirectUri: String) {
    updateMicrosoft365Auth(app: $app, authType: $authType, tenantId: $tenantId, authCode: $authCode, redirectUri: $redirectUri) {
      id
      name
      settings
    }
  }
`

export const UPDATE_OFFICE_365_SETTINGS = gql`
  mutation($settings: MicrosoftSyncSettings!) {
    updateOffice365Settings(settings: $settings) {
      id
      name
      settings
    }
  }
`

export const UPDATE_GOOGLE_AUTH = gql`
  mutation(
    $app: GoogleApp!, $authType: GoogleAuthType!, $authCode: String, $redirectUri: String, $origin: String,
    $serviceAccountDelegatedUserEmail: String, $serviceAccountCredentials: String
  ) {
    updateGoogleAuth(
      app: $app, authType: $authType, authCode: $authCode, redirectUri: $redirectUri, origin: $origin,
      serviceAccountDelegatedUserEmail: $serviceAccountDelegatedUserEmail, serviceAccountCredentials: $serviceAccountCredentials
    ) {
      id
      name
      settings
    }
  }
`

export const UPDATE_GOOGLE_SYNC_SETTINGS = gql`
  mutation($settings: GoogleSyncSettings!) {
    updateGoogleSyncSettings(settings: $settings) {
      id
      name
      settings
    }
  }
`

export const GET_GOOGLE_AUTHORISE_URL = gql`
  query($app: GoogleApp! $redirectUri: String!, $origin: String!) {
    getGoogleAuthUrl(app: $app, redirectUri: $redirectUri, origin: $origin)
  }
`

export const REVOKE_M365_INTEGRATION = gql`
  mutation($app: M365App!, $action: IntegrationRevokeAction!) {
    revokeM365Integration(app: $app, action: $action) {
      id
      name
      ${SETTINGS_RELOAD_FIELDS}
    }
  }
`

export const REVOKE_GOOGLE_INTEGRATION = gql`
  mutation($app: GoogleApp!, $action: IntegrationRevokeAction!, $origin: String!) {
    revokeGoogleIntegration(app: $app, action: $action, origin: $origin) {
      id
      name
      ${SETTINGS_RELOAD_FIELDS}
    }
  }
`

export const DETATCH_ALL_FROM_SYNC = gql`
  mutation ($syncType: SyncType!) {
    detachAllFromSync(syncType: $syncType)
  }
`

export const GENERATE_API_KEY = gql`
  mutation {
    generateApiKey {
      token
    }
  }
`

export const GENERATE_CLIENT_API_KEY = gql`
  mutation {
    generateClientApiKey {
      token
    }
  }
`

export const SEND_BILLING_EXPORT = gql`
  mutation($startDate: Date!) {
    sendBillingExport (startDate: $startDate)
  }
`

export const START_RISK_REPORT = gql`
  mutation ($companyId: ID!, $email: String!, $domain: String) {
    startRiskReport(companyId: $companyId, email: $email, domain: $domain) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const UPGRADE_TRIAL = gql`
  mutation ($companyId: ID!, $domainLock: Boolean, $learnerLimit: Int) {
    upgradeTrial(companyId: $companyId, domainLock: $domainLock, learnerLimit: $learnerLimit) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const UPDATE_COMPANY_ACCOUNT_TYPE = gql`
  mutation($companyId: ID!, $accountType: AccountType) {
    updateCompanyAccountType(companyId: $companyId, accountType: $accountType) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`
export const UPDATE_COMPANY_PARENT_COMPANY = gql`
  mutation($companyId: ID!, $parentCompanyId: ID) {
    updateCompanyParentCompany(companyId: $companyId, parentCompanyId: $parentCompanyId) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const ADVANCE_RISK_REPORT = gql`
  mutation ($companyId: ID!) {
    advanceRiskReport(companyId: $companyId) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const CONVERT_PROSPECT_TO_FREE_TRIAL = gql`
  mutation ($companyId: ID!) {
    convertProspectToFreeTrial(companyId: $companyId) {
      ${USERVICE_UPDATE_FIELDS}
    }
  }
`

export const PROSPECT_RISK_REPORT_FIELDS = `
id
companyId
company {
  name
  domain
  parentCompanyId
  settings
  locale
  parentCompany {
    name
    settings
  }
}
status
domainScan
riskScore
history
uBreachStats {
  breached
  notBreached
  services {
    name
    title
    breachDate
    addedDate
    modifiedDate
    logo
    description
    dataClasses (returnIds: $returnBreachDataClassIds)
    count
    category
  }
  lastCheck
  breachedByCategory {
    high
    medium
    low
  }
}
simulation {
  id
  name
  createdAt
  startDate
  endDate
  results {
    open
    visit
    compromise
    createdAt
  }
}
`

export const GET_PROSPECT_RISK_REPORT = gql`
  query ($companyId: ID!, $preview: Boolean, $returnBreachDataClassIds: Boolean = true) {
    riskReport(companyId: $companyId, preview: $preview) {
      ${PROSPECT_RISK_REPORT_FIELDS}
    }
  }
`

export const REQUEST_NEXT_RISK_REPORT_STEP = gql`
  mutation ($companyId: ID!, $returnBreachDataClassIds: Boolean = true) {
    requestNextRiskReportStep(companyId: $companyId) {
      ${PROSPECT_RISK_REPORT_FIELDS}
    }
  }
`

export const SEND_EMAIL_THEME_TEST = gql`
  mutation($locale: LanguageCode, $emailConfig: JSON!, $useDefaultTenantSettings: Boolean) {
    sendEmailThemeTest (locale: $locale, emailConfig: $emailConfig, useDefaultTenantSettings: $useDefaultTenantSettings)
  }
`

export const UPDATE_COMPANY_LOCALE_SETTINGS = gql`
  mutation($locale: LanguageCode!, $contentLocales: [LanguageCode!]) {
      updateCompanyLanguageSettings(locale: $locale, contentLocales: $contentLocales)
  }
`

export const GET_COMPANY_LOCALE_SETTINGS = gql`
  query {
      company {
          locale
          contentLocales
      }
  }
`

export const UPDATE_DEFAULT_TENANT_LOCALE_SETTINGS = gql`
  mutation($locale: LanguageCode!, $contentLocales: [LanguageCode!]) {
      updateDefaultTenantLanguageSettings(locale: $locale, contentLocales: $contentLocales)
  }
`

export const GET_DEFAULT_TENANT_LOCALE_SETTINGS = gql`
  query {
      getDefaultTenantLanguageSettings {
          locale
          contentLocales
      }
  }
`

export const UPDATE_COMPANY_TIMEZONE = gql`
  mutation($timezone: String!) {
      updateCompanyTimezone(timezone: $timezone)
  }
`

export const GET_COMPANY_TIMEZONE = gql`
  query {
      company {
          timezone
      }
  }
`

export const UPDATE_DEFAULT_TENANT_TIMEZONE = gql`
  mutation($timezone: String!) {
      updateDefaultTenantTimezone(timezone: $timezone)
  }
`

export const GET_DEFAULT_TENANT_TIMEZONE = gql`
  query {
      getDefaultTenantTimezone
  }
`

export const GET_SUPPORTED_DOMAINS = gql`
    query {
        supportedDomains
    }
`

export const GET_COMPULSORY_COURSES = gql`
  query {
      getCompulsoryCourses
  }
`

export const GET_DEFAULT_TENANT_COMPULSORY_COURSES = gql`
  query {
    getDefaultTenantCompulsoryCourses
  }
`

export const UPDATE_COMPANY_COMPULSORY_COURSES = gql`
    mutation ($courseIds: [ID!]) {
        updateCompulsoryCourses(courseIds: $courseIds) {
          id
          compulsoryCourses
        }
    }
`

export const UPDATE_DEFAULT_TENANT_COMPULSORY_COURSES = gql`
    mutation ($courseIds: [ID!]) {
      updateDefaultTenantCompulsoryCourses(courseIds: $courseIds) {
        id
        settings
      }
    }
`

export const RISK_REPORT_SIGN_UP_WIDGET_FIELDS = `
enabled
accessKey
content
customFontValue
customFontURL
successMessageTitle
successMessageTitleFontColor
successMessageTitleFontSize
successMessageBody
successMessageBodyFontColor
successMessageBodyFontSize
`

export const GET_RISK_REPORT_SIGN_UP_WIDGET = gql`
  query {
    companySignUpWidget {
      ${RISK_REPORT_SIGN_UP_WIDGET_FIELDS}
    }
  }
`

export const UPDATE_RISK_REPORT_SIGN_UP_WIDGET = gql`
  mutation (
    $content: JSON, $accessKey: ID, $enabled: Boolean, $customFontValue: String, $customFontURL: String,
    $successMessageTitle: String, $successMessageTitleFontColor: String, $successMessageTitleFontSize: Float,
    $successMessageBody: String, $successMessageBodyFontColor: String, $successMessageBodyFontSize: Float
  ) {
    updateCompanySignUpWidget (
      content: $content, accessKey: $accessKey, enabled: $enabled, customFontValue: $customFontValue, customFontURL: $customFontURL,
      successMessageTitle: $successMessageTitle, successMessageTitleFontColor: $successMessageTitleFontColor, successMessageTitleFontSize: $successMessageTitleFontSize,
      successMessageBody: $successMessageBody, successMessageBodyFontColor: $successMessageBodyFontColor, successMessageBodyFontSize: $successMessageBodyFontSize
    ) {
      ${RISK_REPORT_SIGN_UP_WIDGET_FIELDS}
    }
  }
`

export const DELETE_RISK_REPORT = gql`
  mutation($companyId: ID!) {
    deleteRiskReport(companyId: $companyId)
  }
`

export const RESET_RISK_REPORT = gql`
  mutation($companyId: ID!) {
    resetRiskReport(companyId: $companyId)
  }
`

export const RISK_REPORT_UBREACH_COMPLETE_CHECK = gql`
  mutation($companyId: ID!) {
    riskReportUBreachCompleteCheck(companyId: $companyId)
  }
`
export const UPGRADE_UBREACH_PRO = gql`
  mutation {
    upgradeMSPUBreachPro
  }
`

const COMPANY_SSO_SETTINGS_FIELDS = gql`
fragment ssoSettingsFields on CompanySSOSettings {
  enabled
  adminLoginMethod
  endUserLoginMethod
  samlEntryPoint
  samlSigningCertificateFingerprint
  samlEndUserOptionForced
  disableRequestedAuthnContext
  disableUsecureMfa
}
`

export const GET_COMPANY_SSO_SETTINGS = gql`
  ${COMPANY_SSO_SETTINGS_FIELDS}
  query {
    getSSOSettings {
      ...ssoSettingsFields
    }
  }
`
export const UPDATE_COMPANY_SSO_SETTINGS = gql`
  ${COMPANY_SSO_SETTINGS_FIELDS}
  mutation (
    $enabled: Boolean!, $adminLoginMethod: CompanySSOAdminLoginMethod, $endUserLoginMethod: CompanySSOEndUserLoginMethod, $samlEntryPoint: String, $samlSigningCertificate: String, $samlEndUserOptionForced: Boolean, $disableRequestedAuthnContext: Boolean, $disableUsecureMfa: Boolean
  ) {
    updateSSOSettings(
      enabled: $enabled, adminLoginMethod: $adminLoginMethod, endUserLoginMethod: $endUserLoginMethod, samlEntryPoint: $samlEntryPoint, samlSigningCertificate: $samlSigningCertificate, samlEndUserOptionForced: $samlEndUserOptionForced, disableRequestedAuthnContext: $disableRequestedAuthnContext, disableUsecureMfa: $disableUsecureMfa
    ) {
      ...ssoSettingsFields
    }
  }
`
export const COPY_DEFAULT_TENANT_EMAIL_SETTING = gql`
  mutation($emailId: String!, $settingIds: [CopyDefaultTenantEmailSettingIds!]) {
    copyDefaultTenantEmailSetting(emailId: $emailId, settingIds: $settingIds)
  }
`

export const COMPANY_AUTOCOMPLETE_LOOKUP = gql`
  query($searchTerm: String!, $accountType: AccountType, $limit: Int) {
    uServiceCompanyQuickSearch(searchTerm: $searchTerm, accountType: $accountType, limit: $limit) {
      id
      name
    }
  }
`
