import React from 'react'
import { Alert } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import I18n from 'i18n-js'

import { connect } from '../../hocs'

const trOpt = { scope: 'main.freeTrialMessage' }

const PlanExpiryMessageAlert = styled(Alert)`
  padding: 8px 15px;
  text-align: center;

  .ant-alert-icon {
    left: 0;
    margin-right: 8px;
    position: relative;
    top: 3px;
  }

  .ant-alert-message {
    line-height: 1;
    vertical-align: bottom;
  }
`

const PlanExpiryMessage = ({ expires = null, planValid = true, planName }) => {
  let message
  let type

  const remainingDays = Math.ceil(moment().diff(expires, 'day', true) * -1)
  const isExpired = planValid && remainingDays === 0
  const isExpiringSoon = planValid && remainingDays < 3

  if (planName === 'freeTrial') {
    if (isExpired) {
      message = I18n.t('expiredMessage', trOpt)
      type = 'warning'
    } else if (isExpiringSoon) {
      message = I18n.t('remainingMessage', { ...trOpt, count: remainingDays })
      type = 'info'
    }
  }

  // Setting an expiry date on a paid plan sets the planName to 'custom'
  if (planName === 'custom') {
    if (isExpired) {
      message = I18n.t('main.expiredPaidPlanMessage')
      type = 'warning'
    } else if (isExpiringSoon) {
      message = I18n.t('main.paidPlanExpiringMessage', { count: remainingDays })
      type = 'info'
    }
  }

  return !(message && type) ? null : <PlanExpiryMessageAlert {...{ message, type }} showIcon banner />
}

const planSelector = state => {
  const { session: { expires = null, planValid = true, planName } = {} } = state || {}
  return { expires, planValid, planName }
}

export default connect(
  planSelector
)(PlanExpiryMessage)
