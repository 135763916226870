import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from 'antd'
import I18n from 'i18n-js'

import EditCourseSlate from './EditCourseSlate'
import { DEFAULT_LANGUAGE } from '../../constants/languages'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'editCourse.editCourseCloudflareStream' }

const HlsManifestFormField = ({ hlsManifestUrl, onChange }) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()

  return (
    <Form.Item label={I18n.t('hlsManifestUrl', trOpt)}>
      <Input
        onChange={onChange}
        value={hlsManifestUrl}
        disabled={!hasAllSessionPermissions([permissions.COURSE_SUPER_UPDATE])}
      />
    </Form.Item>
  )
}

class EditCourseCloudflareStream extends Component {
  constructor (props) {
    super(props)

    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.updateIntro = this.updateIntro.bind(this)
    this.handleHlsManifestUrlChange = this.handleHlsManifestUrlChange.bind(this)
  }

  handleTitleChange (event) {
    this.props.updateTitle(event.target.value)
  }

  updateIntro ({ intro }) {
    this.props.updateContent({ ...this.props.content, intro })
  }

  handleHlsManifestUrlChange (event) {
    this.props.updateContent({ ...this.props.content, hlsManifestUrl: event.target.value })
  }

  render () {
    const { title, content, locale, slideId, updateId } = this.props
    const { hlsManifestUrl = '' } = content || {}

    return (
      <div>
        <Form.Item label={I18n.t('editCourse.common.title')}>
          <Input
            onChange={this.handleTitleChange}
            value={title}
          />
        </Form.Item>
        <Form.Item label={I18n.t('editCourse.common.intro')}>
          <EditCourseSlate updateContent={this.updateIntro} {...{ locale, content, slideId, updateId }} />
        </Form.Item>
        <HlsManifestFormField hlsManifestUrl={hlsManifestUrl} onChange={this.handleHlsManifestUrlChange} />
      </div>
    )
  }
}

EditCourseCloudflareStream.propTypes = {
  content: PropTypes.object,
  locale: PropTypes.string,
  title: PropTypes.string,
  updateTitle: PropTypes.func,
  updateContent: PropTypes.func
}

EditCourseCloudflareStream.defaultProps = {
  content: {},
  locale: DEFAULT_LANGUAGE,
  title: '',
  updateTitle: () => {},
  updateContent: () => {}
}

export default EditCourseCloudflareStream
