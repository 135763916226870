import React from 'react'
import ReactDOM from 'react-dom'

import App from './views/App'
import { StateProvider } from './state'
import rootReducer, { initialState } from './state/reducers'
import './helpers/sentry'
import './helpers/posthog'
import 'plyr/dist/plyr.css'
import './assets/css/all.css'

ReactDOM.render(
  <StateProvider initialState={initialState} reducer={rootReducer}>
    <App />
  </StateProvider>,
  document.getElementById('root')
)
