export function sortByName (sortOrder = 'asc', locale = navigator.language) {
  return (a, b) => {
    if (sortOrder === 'asc') {
      return a.name?.localeCompare(b.name, locale) ?? 0
    } else {
      return b.name?.localeCompare(a.name, locale) ?? 0
    }
  }
}

export function sortResultsBy (
  sortBy = DEFAULT_SORT_BY,
  locale = navigator.language
) {
  switch (sortBy) {
    case 'NAME_ASC':
      return sortByName('asc', locale)
    case 'NAME_DESC':
      return sortByName('desc', locale)
    default:
      return sortByName('asc', locale)
  }
}

export const DEFAULT_SORT_BY = 'NAME_ASC'

export const DEFAULT_SORT_BY_OPTIONS = {
  NAME_ASC: {
    label: 'common.librarySorting.nameAsc'
  },
  NAME_DESC: {
    label: 'common.librarySorting.nameDesc'
  }
}
