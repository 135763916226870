import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import { Empty, Pagination } from 'antd'
import I18n from 'i18n-js'
import _intersection from 'lodash/intersection'
import _isArray from 'lodash/isArray'
import _isBoolean from 'lodash/isBoolean'
import _isEmpty from 'lodash/isEmpty'
import _isFunction from 'lodash/isFunction'
import _isInteger from 'lodash/isInteger'
import _isNil from 'lodash/isNil'
import _isString from 'lodash/isString'
import _toInteger from 'lodash/toInteger'
import _pick from 'lodash/pick'

import TemplateTile, { TileCard } from './TemplateTile'
import { LoadingBlock, ErrorAlerts as _ErrorAlerts, ModalPager } from '../common'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { ALL_FILTER_VALUE, NONE_FILTER_VALUE } from './TemplateHeader'
import { UPHISH_ATTACK_TYPES } from '../../constants/uPhish'
import { DEFAULT_SORT_BY, sortResultsBy } from '../../helpers/sortBy'

const trOpt = { scope: 'uPhish.templateTiles' }

const TileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  left: -5px;
  width: calc(100% + 10px);

  .ant-empty {
    margin: 50px auto 0;
  }

  ${TileCard} {
    padding: 0 5px 10px;

    @media (max-width: 575px) {
      width: 100%;
    }
    @media (min-width: 576px) {
      width: 50%;
    }
    @media (min-width: 992px) {
    width: ${100 / 3}%;
    }
    @media (min-width: 1200px) {
      width: 25%;
    }
    @media (min-width: 1600px) {
      width: 20%;
    }
    @media (min-width: 2000px) {
      width: ${100 / 6}%;
    }
    @media (min-width: 2400px) {
      width: ${100 / 8}%;
    }

    .ant-card {
      height: 100%;
    }
  }
`

const ErrorAlerts = styled(_ErrorAlerts)`
  margin-bottom: 15px;
`

const PaginationContainer = styled.div`
  margin: 20px 0 5px;
  text-align: right;

  .ant-pagination-options-size-changer.ant-select {
    margin-right: 7px;
  }
`

const TemplateTilesModal = ({ component: Modal, id, title, visible, setVisible, afterClose, templates = [], onClick = () => { }, ...modalProps }) => {
  let current
  let prev = null
  let next = null

  if (id) {
    const index = templates.findIndex(template => template.id === id)
    if (index !== -1) {
      current = index + 1
      prev = index - 1
      prev = prev < 0 ? templates.length - 1 : prev
      prev = (templates[prev] || { id: null }).id
      next = index + 1
      next = next > templates.length - 1 ? 0 : next
      next = (templates[next] || { id: null }).id
    }
  }

  return (
    <Modal
      {...modalProps}
      {...{ id, title, visible, setVisible, afterClose }}
      title={
        <ModalPager
          count={templates.length}
          current={current}
          prev={prev} next={next} onClick={onClick}
        />
      }
    />
  )
}

const FREEFORM_TEMPLATE = {
  id: 'freeform',
  get name () { return I18n.t('writeYourOwnEmail', trOpt) }
}

export const TemplateTiles = ({
  templates: rawTemplates = [], selectedId, actions, showCreate = false, showFreeform = false,
  onClick = () => { }, onView = () => { }, onEdit = () => { }, onDelete = () => { }, onSendTest = () => { },
  modal: modalProps, filters: { searchText, type, category, contentLocales, hasAttachment, attackTypes } = {}, companyId: userCompanyId, categories,
  emptyMessage = I18n.t('emptyMessage', trOpt), onSelectedTemplateUpdate, showCategory,
  setContentLocaleCounts,
  showPagination = false, currentPage, setCurrentPage, pageSize, setPageSize, pageSizeOptions = ['50', '100', '250'], paginationTotalTrKey = 'common.paginationTotal',
  localesFieldId = 'locales', showLocalesIcon = false,
  sortBy = DEFAULT_SORT_BY, locale
}) => {
  const [allTemplates, setAllTemplates] = useState([])
  const [allTemplatesReady, setAllTemplatesReady] = useState(false)
  useEffect(() => {
    const allTemplates = []
    if (showCreate) allTemplates.push({ id: 'create', ignoreFilters: true })
    if (showFreeform) allTemplates.push({ id: 'freeform', ignoreFilters: true })
    if (_isArray(categories) && !_isEmpty(categories)) {
      allTemplates.push(...rawTemplates.map(template => {
        const category = template.category ? categories.find(c => c.id === template.category) : null

        return {
          ...template,
          categoryText: category ? category.title : null
        }
      }))
    } else {
      allTemplates.push(...rawTemplates)
    }
    setAllTemplates(allTemplates)

    if (_isFunction(setContentLocaleCounts)) {
      setContentLocaleCounts(rawTemplates.reduce((acc, { [localesFieldId]: locales }) => {
        if (_isArray(locales) && !_isEmpty(locales)) {
          locales.forEach(locale => {
            if (_isNil(acc[locale])) {
              acc[locale] = 1
            } else {
              acc[locale] += 1
            }
          })
        }
        return acc
      }, {}))
    }
    setAllTemplatesReady(true)
  }, [rawTemplates, categories, setContentLocaleCounts, showCreate, showFreeform, localesFieldId])
  const [templates, setTemplates] = useState([])
  const [templatesReady, setTemplatesReady] = useState(false)
  useEffect(() => {
    if (!allTemplatesReady) return
    const searchTerm = searchText ? searchText.toLowerCase().trim() : null
    const hasCategoryNone = category === NONE_FILTER_VALUE
    const hasCategoryValue = !_isNil(category) && category !== ALL_FILTER_VALUE
    const hasContentLocales = _isArray(contentLocales) && !_isEmpty(contentLocales)
    const hasHasAttachmentValue = hasAttachment && hasAttachment !== ALL_FILTER_VALUE
    const hasAttackTypeValue = _isArray(attackTypes) && JSON.stringify(attackTypes) !== JSON.stringify(UPHISH_ATTACK_TYPES)

    let filteredTemplates = allTemplates

    if (searchTerm || ['default', 'custom'].includes(type) || hasCategoryNone || hasCategoryValue || hasContentLocales || hasHasAttachmentValue || attackTypes) {
      filteredTemplates = allTemplates.reduce((acc, template) => {
        const { name, companyId, category: tCategory, [localesFieldId]: locales, ignoreFilters, attachmentFile, attackType, pageTitle, subject, senderName } = template
        let match = true
        if (!ignoreFilters) {
          if (match && searchTerm) {
            match = (name && String(name).toLowerCase().includes(searchTerm)) ||
              (pageTitle && String(pageTitle).toLowerCase().includes(searchTerm)) ||
              (subject && String(subject).toLowerCase().includes(searchTerm)) ||
              (senderName && String(senderName).toLowerCase().includes(searchTerm))
          }
          if (match && type === 'default') {
            // Custom to this company, treat partner templates as default
            match = companyId !== userCompanyId
          } else if (match && type === 'custom') {
            match = companyId === userCompanyId
          }
          if (match && hasCategoryNone) {
            match = tCategory === null
          } else if (match && hasCategoryValue) {
            match = tCategory === category
          }
          if (match && hasContentLocales) {
            match = _isArray(locales) && !_isEmpty(locales) && _intersection(locales, contentLocales).length > 0
          }
          if (match && hasHasAttachmentValue) {
            match = (hasAttachment === 'has-attachment' && attachmentFile !== null) || (hasAttachment === 'no-attachment' && attachmentFile === null)
          }
          if (match && hasAttackTypeValue) {
            match = attackType.some((at) => attackTypes.includes(at))
          }
        }
        if (match) {
          acc.push(template)
        }
        return acc
      }, [])
    }

    setTemplates([...filteredTemplates].sort(sortResultsBy(sortBy, locale)))

    setTemplatesReady(true)
  }, [searchText, type, userCompanyId, allTemplatesReady, allTemplates, category, hasAttachment, contentLocales, localesFieldId, attackTypes, sortBy, locale])
  useEffect(() => {
    if (!_isFunction(onSelectedTemplateUpdate)) {
      return
    }
    let selectedTemplate = null
    if (selectedId === 'freeform') {
      selectedTemplate = FREEFORM_TEMPLATE
    } else if (selectedId && selectedId !== 'create') {
      selectedTemplate = templates.find(template => template.id === selectedId) || null
    }
    onSelectedTemplateUpdate(selectedTemplate)
  }, [templates, selectedId, onSelectedTemplateUpdate])

  let modal
  if (modalProps) {
    modal = (
      <TemplateTilesModal
        id={selectedId}
        {...{ templates, onClick }}
        {...modalProps}
      />
    )
  }

  const [paginationReady, setPaginationReady] = useState(false)
  const usePagination = useMemo(() => {
    return showPagination &&
      _isInteger(currentPage) && _isFunction(setCurrentPage) &&
      _isInteger(pageSize) && _isFunction(setPageSize)
  }, [showPagination, currentPage, setCurrentPage, pageSize, setPageSize])
  const onPageChange = useCallback((page) => {
    setCurrentPage(page)
  }, [setCurrentPage])
  const onShowSizeChange = useCallback((current, pageSize) => {
    setCurrentPage(current)
    setPageSize(pageSize)
  }, [setCurrentPage, setPageSize])

  const [templatesPage, setTemplatesPage] = useState([])
  useEffect(() => {
    if (!usePagination) return
    const startIndex = (currentPage - 1) * pageSize
    const endIndex = startIndex + pageSize
    const templatesPage = templates.slice(startIndex, endIndex)
    setTemplatesPage(templatesPage)
    setPaginationReady(true)
  }, [usePagination, templates, currentPage, pageSize, setTemplatesPage])
  // Number of pages for current result set and page size
  const pageCount = Math.ceil(templates.length / pageSize)
  // Number of pages for current result set on the smallest page size
  const maxPageCount = Math.ceil(templates.length / _toInteger(pageSizeOptions[0]))

  useEffect(() => {
    if (!(usePagination && templatesReady && paginationReady)) return
    const pageCount = Math.ceil(templates.length / pageSize)
    if (currentPage > pageCount) {
      setCurrentPage(1)
    }
  }, [usePagination, templatesReady, paginationReady, templates, pageSize, currentPage, setCurrentPage])

  return (
    <>
      {modal}
      <TileContainer>
        {(usePagination ? templatesPage : templates).map(template => {
          if (template.id === 'create') return <TemplateTile key={template.id} template={{ id: 'create', name: I18n.t('createNewTemplate', trOpt), previewThumb: '/images/templates/create-template.png' }} onClick={onClick} />
          if (template.id === 'freeform') return <TemplateTile key={template.id} selected={selectedId === 'freeform'} template={FREEFORM_TEMPLATE} onClick={onClick} />
          return <TemplateTile key={template.id} selected={template.id === selectedId} {...{ template, actions, onClick, onView, onEdit, onDelete, onSendTest, showCategory, showLocalesIcon, localesFieldId }} />
        })}
        {
          !showFreeform && !showCreate && templatesReady && _isEmpty(templates) &&
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={emptyMessage}
            />
        }
      </TileContainer>
      {
        usePagination && maxPageCount > 1 &&
          <PaginationContainer>
            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              pageSizeOptions={pageSizeOptions}
              pageSize={pageSize}
              current={currentPage}
              onChange={onPageChange}
              total={templates.length}
              showQuickJumper={pageCount > 10}
              showTotal={(total, range) => I18n.t(paginationTotalTrKey, { rangeStart: range[0], rangeEnd: range[1], total })}
            />
          </PaginationContainer>
      }
    </>
  )
}

const TemplateTilesQuery = ({
  query, queryName, customOnly = false, queryVariables = {},
  showCreate = false, showFreeform = false,
  title: titleProp, errorMessage: defaultError = I18n.t('errorMessage', trOpt),
  setTemplatesLoading,
  ...tilesProps
}) => {
  const customTilesProps = { showCreate, showFreeform }
  const title = _isString(titleProp) ? <h4>{titleProp}</h4> : titleProp

  const { loading, error, data } = useQuery(query, { variables: { ...queryVariables, customOnly } })
  useEffect(() => {
    if (_isFunction(setTemplatesLoading) && _isBoolean(loading)) {
      setTemplatesLoading(loading)
    }
  }, [setTemplatesLoading, loading])

  if (loading) return <LoadingBlock fullScreen loading={loading} />
  const templates = data?.[queryName] || []
  const hasAny = templates.length > 0

  return (
    <>
      <ErrorAlerts {...{ error, defaultError }} />
      {title}
      {
        hasAny || showCreate || showFreeform
          ? <TemplateTiles templates={templates} {...tilesProps} {...customTilesProps} />
          : (!error && <h4>{I18n.t('emptyMessage', trOpt)}</h4>)
      }
    </>
  )
}

export default connect(
  state => ({
    ..._pick(selectors.session.get(state), ['userId', 'companyId', 'locale'])
  })
)(TemplateTilesQuery)
