import React from 'react'
import I18n from 'i18n-js'
import FilterField from './FilterField'
import { Select } from 'antd'
import { DEFAULT_SORT_BY_OPTIONS } from '../../helpers/sortBy'

export function SortByOptions ({ sortBy, onSortByChange, sortByOptions = DEFAULT_SORT_BY_OPTIONS }) {
  return (
    <FilterField width={100}>
      <label>{I18n.t('common.librarySorting.sortByLabel')}</label>
      <Select value={sortBy} onChange={onSortByChange}>
        {Object.entries(sortByOptions).map(([key, { label }]) => (
          <Select.Option value={key} key={key}>{I18n.t(label)}</Select.Option>
        ))}
      </Select>
    </FilterField>
  )
}
