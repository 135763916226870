import React, { useCallback, forwardRef } from 'react'
import Plyr from 'plyr'
import Hls from 'hls.js'
import styled from 'styled-components'
import useTheme from '../../hooks/useTheme'
import { MIN_BREAKPOINT } from '../../constants/style'
import { LoadingBlock } from '../common'

// The height in the style below sets the video container div's dimensions to a 16:9 aspect ratio relative to the available width
// usecure's video content is produced in 16:9 and this is likely true of ours clients' content too.
// Any video content using a different aspect ratio will have black bars to fill the space

// We can restyle the Plyr controls using CSS variables
// https://github.com/sampotts/plyr#html
const VideoContainer = styled.div`
  --plyr-color-main: ${({ theme }) => theme.primary};

  background-color: ${({ status }) => status === 'ready' ? '#000000' : 'transparent'};
  display: ${({ status }) => status === 'failed' ? 'none' : 'block'};
  position: relative;
  text-align: center;
  width: 100%;

  height: calc((100vw - 3rem) / (16 / 9));
  @media (min-width: ${MIN_BREAKPOINT}) {
    height: calc((75vw - 8.2rem) / (16 / 9));
  }

  .plyr {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .plyr__control--overlaid {
    border-radius: 10%;
    padding: calc(var(--plyr-control-spacing, 10px)* 1.5) calc(var(--plyr-control-spacing, 10px)* 2.5);
  }

  .plyr__controls__item {
    :first-child {
      @media (min-width: ${MIN_BREAKPOINT}) {
        padding: calc(var(--plyr-control-spacing, 10px)* .75) calc(var(--plyr-control-spacing, 10px)* 2.5);
      }
    }
  }

  .plyr__volume {
      max-width: initial;
      min-width: initial;
      width: auto;
      position: relative;

      input[data-plyr="volume"] {
        width: 7rem;
        margin: 0;
        position: absolute;
        left: 50%;
        top: -1rem;
        transform-origin: left;
        transform: rotate(-90deg);
        opacity: 0;
        z-index: -1;
        transition: all .2s;

        :hover {
          opacity: 1;
          z-index: 1;
        }
      }
    } 
`

const initHls = (ref, hlsManifest) => {
  if (Hls.isSupported()) {
    const hls = new Hls()
    hls.loadSource(hlsManifest)
    hls.attachMedia(ref)

    return hls
  }
  return null
}

const LearnerCourseCloudflareStreamPlayer = forwardRef(({ id, hlsManifest, status }, ref) => {
  const theme = useTheme()

  const videoRef = useCallback(node => {
    if (!node) return // Exit if node is null

    ref.current?.hls && ref.current.hls.destroy()

    const player = new Plyr(node, {
      captions: {
        active: true,
        update: true,
        language: 'en'
      },
      displayDuration: true,
      controls: [
        'play-large',
        'play',
        'current-time',
        'duration',
        'progress',
        'mute',
        'volume',
        'captions',
        'fullscreen'
      ],
      keyboard: {
        focused: false,
        global: false
      },
      storage: {
        enabled: true
      }
    })

    const hls = initHls(node, hlsManifest)

    ref.current = {
      player,
      hls
    }
  }, [
    hlsManifest,
    ref
  ])

  return (
    <VideoContainer status={status} theme={theme}>
      <LoadingBlock loading={status === 'init' || status === 'start' || status === 'loading'} fullScreen={false} />
      <video className={`plyr-${id}`} ref={videoRef} />
    </VideoContainer>
  )
})

export default LearnerCourseCloudflareStreamPlayer
