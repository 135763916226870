import React, { useCallback, useRef } from 'react'
import { Modal } from 'antd'
import I18n from 'i18n-js'

import MutationForm from '../MutationForm/MutationForm'
import { SEND_POLICY } from '../Queries/uPolicy'
import { getRecipientIds } from '../../helpers/uPhish'
import { invalidatePoliciesQueryCache } from '../../helpers'
import { showSendPoliciesMessages } from './SendPolicyLearnerModal'

const trOpt = { scope: 'modals.sendPolicyModal' }

// TODO Custom learnerAndGroupSelect field to show new and reminder recipients
const SendPolicyModal = ({ policyIds = [], policies = [], visible = false, setVisible = () => {}, afterClose = () => {}, refetchQueries }) => {
  const form = useRef(null)
  const closeModal = useCallback(async () => {
    if (form && form.current) {
      await form.current.reset()
    }
    setVisible(false)
  }, [setVisible, form])

  const onSuccess = useCallback(result => {
    const { data: { sendPolicy: { completed = [], policyIds = [], learnerIds = [] } = {} } = {} } = result || {}

    showSendPoliciesMessages({ completed, learnerIds, policyIds, policies })
    closeModal()
  }, [closeModal, policies])

  const fields = [
    {
      id: 'recipients',
      label: I18n.t('recipients', trOpt),
      type: 'learnerAndGroupSelect',
      required: true,
      removeLabel: I18n.t('common.removeAllRecipients'),
      mutateValue: recipients => {
        const { learners = [], groups = [] } = recipients || {}
        return {
          learners: getRecipientIds(learners),
          groups: getRecipientIds(groups)
        }
      },
      includeNoGroup: true
    },
    {
      id: 'skipEmails',
      type: 'checkbox',
      label: I18n.t('uPolicy.common.skipNotificationEmail'),
      renderLabelInline: true,
      value: skipEmails => skipEmails || false
    }
  ]

  const [policy] = policies

  let title
  if (policyIds.length === 1 && policy && policy.name) {
    title = I18n.t('titleName', { ...trOpt, name: policy.name })
  } else {
    title = policyIds.length === 1 ? I18n.t('uPolicy.common.sendPolicy') : I18n.t('uPolicy.common.sendPolicies')
  }

  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={closeModal}
      afterClose={afterClose}
      footer={null}
    >
      <MutationForm
        mutation={SEND_POLICY}
        onSuccess={onSuccess}
        failureMessage={I18n.t('errorMessage', { ...trOpt, count: policyIds.length })}
        submitLabel={policyIds.length === 1 ? I18n.t('uPolicy.common.sendPolicy') : I18n.t('uPolicy.common.sendPolicies')}
        ref={form}
        fields={fields}
        variables={{ policyIds }}
        update={invalidatePoliciesQueryCache}
        refetchQueries={refetchQueries}
      />
    </Modal>
  )
}

export default SendPolicyModal
