import React from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'

const RequestIdContainer = styled.div`
  font-size: 10px;
  height: 0;
  position: relative;
  top: -15px;
`

const RequestId = ({ children }) => (
  <RequestIdContainer>{I18n.t('signIn.requestId')}: {children}</RequestIdContainer>
)

export default RequestId
