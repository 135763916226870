import React, { useState, useEffect, useCallback } from 'react'
import I18n from 'i18n-js'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { Button } from 'antd'
import styled from 'styled-components'

import { ShortLoader } from '../../components/common/Loading'
import { Row, Column } from '../../components/EndUserPortal/GridFlexiLayout'
import { RequestId, SignInHeader } from '../../components/SignIn'
import { ErrorAlerts } from '../../components/common'
import { UsecureError, addDelay } from '../../helpers'
import { useGlobalState } from '../../hooks'
import { creators as viewActions } from '../../state/actions/view'
import { captureSentryError } from '../../helpers/sentry'
import { postSessionChannelMessage, setSessionToken } from '../../helpers/session'
import { withRefreshSessionState } from '../../hocs'
import { navigateToApp } from '../../helpers/signIn'

const trOpt = { scope: 'signIn' }

const LoadingMessage = styled.h1`
  color: ${({ theme }) => theme.primary};
  text-align: center;
`

const AdminUserSAMLResponse = ({ refreshSessionState }) => {
  const [status, setStatus] = useState('ready')
  const [error, setError] = useState(null)
  const [requestId, setRequestId] = useState(null)
  const routeParams = useParams()
  const { search } = useLocation()
  const history = useHistory()
  const { setLoadingVisible } = useGlobalState(
    undefined,
    useCallback(dispatch => ({
      setLoadingVisible: loading => dispatch(viewActions.loading(loading))
    }), [])
  )

  // "On Mount" hook
  useEffect(() => {
    if (status !== 'ready') return

    setLoadingVisible(false)

    const { status: statusParam } = routeParams
    const queryParams = new URLSearchParams(search)
    const token = queryParams.get('token')
    const errorCode = queryParams.get('errorCode')

    if (statusParam === 'success' && token) {
      // Initialise end user session and navigate to home page or route indicated by targetPath param
      setStatus('success')
      addDelay({
        delay: 1500,
        action: async () => {
          // Set admin session token
          setSessionToken(token)
          // Initialise session & settings global state
          await refreshSessionState(false)
          // Broadcast new session refresh instruction to other tabs
          postSessionChannelMessage('sessionRefresh')
        },
        complete: () => {
          navigateToApp({ history, targetPath: queryParams.get('targetPath') })
        },
        failure: error => {
          captureSentryError(error, { msg: 'Admin User SSO SAML Auth Response - ERROR' })
          // AUP-SIF - Session Initialisation Failure
          setError(new UsecureError(I18n.t('samlSSOError', trOpt), { errorCode: 'SAML-AUP-SIF' }))
          setStatus('fail')
        }
      })
    } else if (statusParam === 'fail' && errorCode) {
      // Show failure state with error code for known error
      setError(new UsecureError(I18n.t('samlSSOError', trOpt), { errorCode }))
      setStatus('fail')
    } else {
      // Show failure state without error code
      setStatus('fail')
    }
    setRequestId(queryParams.get('requestId'))
  }, [status, routeParams, search, history, refreshSessionState, setLoadingVisible])

  const onLoginRetryClick = useCallback(() => {
    navigateToApp({ history, targetPath: new URLSearchParams(search).get('targetPath') })
  }, [history, search])

  if (status === 'ready') return null

  return (
    <Row className='full-height justify align' justify='space-around' alignItems='center' alignContent='center'>
      <Column className='align' align-items='center' align-content='center' flex='0 0 30em' style={{ padding: '2em' }}>
        <SignInHeader bottomMargin='2em'>
          {I18n.t('common.login')}
        </SignInHeader>
        {status !== 'fail' && (
          <>
            <LoadingMessage>{I18n.t('samlSSOLoadingMessage', trOpt)}</LoadingMessage>
            <ShortLoader />
          </>
        )}
        {status === 'fail' && (
          <>
            <ErrorAlerts error={error || new Error(I18n.t('samlSSOError', trOpt))} defaultError={I18n.t('samlSSOError', trOpt)} includeErrorCode />
            {requestId && <RequestId>{requestId}</RequestId>}
            <Button onClick={onLoginRetryClick} icon='redo'>{I18n.t('forgottenPasswordLink.backToLogin', trOpt)}</Button>
          </>
        )}
      </Column>
    </Row>
  )
}

export default withRefreshSessionState(
  AdminUserSAMLResponse
)
