import * as Sentry from '@sentry/react'
import _isArray from 'lodash/isArray'

import { CLIENT_ENV } from '../constants/environment'

const { REACT_APP_SENTRY_ENV: SENTRY_ENV, REACT_APP_SENTRY_DSN: SENTRY_DSN, REACT_APP_SENTRY_DEBUG: SENTRY_DEBUG } = window.__USECURE_CONFIG__ || {}
const LOG_BEFORE_SEND = ['allow', 'true'].includes(SENTRY_DEBUG)
const ALLOW_BEFORE_SEND = SENTRY_DEBUG === 'allow'

const sentrySpec = {
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV || CLIENT_ENV,
  integrations: [
    new Sentry.BrowserTracing()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
}

if (LOG_BEFORE_SEND) {
  sentrySpec.beforeSend = event => {
    // Log Sentry event to log without sending it
    // Useful for debugging what we send to Sentry with creating issues
    // This method won't be called unless REACT_APP_SENTRY_DSN is in place
    console.log('Sentry Event', event)
    // Block event submission unless allowed (REACT_APP_SENTRY_DEBUG === 'allow')
    if (!ALLOW_BEFORE_SEND) return null

    return event
  }
}

Sentry.init(sentrySpec)

/**
 * Sets the Sentry user for the current session
 * @param {Object} user - Specific for the Sentry user. Any keys added to this object will be passed to Sentry unless otherwise stated
 * @param {String} user.id - The user's id typically that of a usecure learner or admin user
 * @param {('learner'|'user')} user.userType - The value of the userType tag added to each event/issue captured for this user.
 */
export const setSentryUser = ({ id, userType = null, ...rest }) => {
  Sentry.setUser({ id, userType, ...rest })
  Sentry.setTag('userType', userType)
}

/**
 * Sets the Sentry user to a usecure end user (i.e. learner)
 * @param {String} learnerId - The id of the learner
 */
export const setSentryUserFromLearner = learnerId => setSentryUser({ id: learnerId, userType: 'learner' })

/**
 * Sets the Sentry user to a usecure admin user
 * @param {String} userId - The id of the admin user
*/
export const setSentryUserFromAdminUser = userId => setSentryUser({ id: userId, userType: 'user' })

/**
 * Clear the Sentry user
 */
export const clearSentryUser = () => {
  Sentry.setUser(null)
  Sentry.setTag('user_type', null)
}

/**
 * Capture an error to send to Sentry
 * @param {Error|Error[]} errorOrErrors - This is the error (or errors) that will be sent to Sentry.
 * @param {Object} opt - Options object
 * @param {Boolean} opt.logToConsole - Logs error to console via console.error
 * @param {Boolean} opt.msg - Message that appears before console.error output for context
 * @param {String[]} opt.graphQLErrorCodeDenyList - Array of GraphQL error codes that do not need to be sent to Sentry
 */

export const captureSentryError = (errorOrErrors, opt) => {
  if (_isArray(errorOrErrors)) {
    errorOrErrors.forEach(e => captureSentryError(e, opt))
    return
  }

  const error = errorOrErrors
  const { logToConsole = !LOG_BEFORE_SEND, msg, graphQLErrorCodeDenyList } = opt || {}
  if (logToConsole && msg) {
    console.error(msg, error)
  } else if (logToConsole) {
    console.error(error)
  }
  let skipCapture = false
  if (_isArray(error.graphQLErrors)) {
    // Server UsecureError's can specify if they don't need to be captured by Sentry
    skipCapture = error.graphQLErrors.some(ge => ge.extensions?.exception?.isUsecure === true && ge.extensions?.exception?.skipCapture === true)

    // Skip this error if it contains a GraphQL error code in the deny list
    if (!skipCapture && _isArray(graphQLErrorCodeDenyList)) {
      const graphQlErrorCodes = error.graphQLErrors.map(ge => ge.extensions?.code)
      skipCapture = graphQLErrorCodeDenyList.some(code => graphQlErrorCodes.includes(code))
    }
  }
  if (!skipCapture) {
    Sentry.captureException(error)
  }
}

/**
 * Capture a sign in error to send to Sentry. This method will not send UNAUTHENTICATED GraphQL as they aren't usually caused by incorrect credentials and are not worth capturing.
 * @param {Error|Error[]} errorOrErrors - This is the error (or errors) that will be sent to Sentry.
 * @param {Object} opt - Options object
 * @param {Boolean} opt.logToConsole - Logs error to console via console.error
 * @param {Boolean} opt.msg - Message that appears before console.error output for context
 */

export const captureSentrySignInError = (errorOrErrors, opt) =>
  captureSentryError(errorOrErrors, { ...(opt || {}), graphQLErrorCodeDenyList: ['UNAUTHENTICATED'] })

/**
 * Capture an message to send to Sentry
 * @param {String|String[]} msgOrMessages - This is the message (or messages) that will be sent to Sentry.
 * @param {Object} opt - Options object
 * @param {Boolean} opt.logToConsole - Logs error to console via console.info
 */

export const captureSentryMessage = (msgOrMessages, opt) => {
  if (_isArray(msgOrMessages)) {
    msgOrMessages.forEach(e => captureSentryMessage(e, opt))
    return
  }

  const { logToConsole = !LOG_BEFORE_SEND } = opt || {}
  if (logToConsole === true) console.info(msgOrMessages)
  Sentry.captureMessage(msgOrMessages)
}
