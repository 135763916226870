/* global localStorage */
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useReducer, useRef } from 'react'
import styled from 'styled-components'
import I18n from 'i18n-js'
import moment from 'moment'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isNil from 'lodash/isNil'
import _isNumber from 'lodash/isNumber'
import _isString from 'lodash/isString'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'
import _toInteger from 'lodash/toInteger'
import ms from 'ms'

import { ContentBox, CardTitle } from './common'
import LearnerCourseSlate from './LearnerCourseSlate'
import { ProgressButton } from '../common'
import DemoMode from '../../helpers/DemoMode'
import { createAction } from '../../helpers/state'
import { UsecureError, getErrorStrings, renderParagraphsFragmentFromArray } from '../../helpers'
import { IntroDetail } from './LearnerCourseIntro'
import LearnerCourseCloudflareStreamPlayer from './LearnerCourseCloudflareStreamPlayer'
import { getVideoTimestampFromSecondsMatchingDuration } from '../../helpers/video'
import { ULEARN_CLOUDFLARESTREAM_STORAGE_PREFIX } from '../../constants/courses'
import { Button } from 'antd'
import { captureSentryError } from '../../helpers/sentry'

const trOpt = { scope: 'learnerCourse.learnerCourseCloudflareStream' }

const CONTAINER_ID = 'course-slide-video-player'
const VIDEO_TIMEOUT = ms(window.__USECURE_CONFIG__.REACT_APP_VIMEO_TIMEOUT ?? '30s')

const StyledVideoWrapper = styled.div`
  text-align: center;
  width: 100%;
`

const TimeRemaining = styled.div`
  color: rgb(124, 112, 107);
  font-weight: 500;
  font-size: 1.5em;
  margin-top: 5px;
  text-align: center;
`

const ErrorDetail = styled(IntroDetail)`
  margin-top: 3rem;
  max-width: none;
`

const ActionsContainer = styled.div`
  margin-bottom: 10px;

  .ant-btn {
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
  }
`

const DebugErrorOutput = styled.div`
  p {
    font-family: unset;
    font-size: 10px;
    margin-bottom: 2px;
  }
`

const getSavedCurrentTime = storageId => {
  const lastSavedTime = localStorage.getItem(storageId)
  if (lastSavedTime === 'played') {
    return lastSavedTime
  } else if (_isString(lastSavedTime) && !isNaN(lastSavedTime)) {
    return _toInteger(lastSavedTime)
  }
  return null
}

const applyTimeout = async (promise, { timeoutError = I18n.t('common.anErrorOccurred', trOpt), delay = 30000, errorPrefix } = {}) => {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new UsecureError(timeoutError))
    }, delay)
    promise
      .then(value => {
        clearTimeout(timeoutId)
        resolve(value)
      })
      .catch(e => {
        clearTimeout(timeoutId)
        captureSentryError(e, { msg: `LearnerCourseCloudflareStream.applyTimeout${errorPrefix ? ` - ${errorPrefix}` : ''} - ERROR` })
        reject(e)
      })
  })
}

const getErrorMessagesArray = e => {
  let errorMessages = getErrorStrings(e)
  if (errorMessages.length === 0) {
    errorMessages = [`${e}`]
  }
  return errorMessages
}

const cleanupPlayerListeners = player => {
  player.off('loadedmetadata')
  player.off('timeupdate')
  player.off('seeking')
  player.off('languagechange')
  player.off('error')
}

const types = {
  UPDATE: 'UPDATE',
  RESET: 'RESET',
  FAIL: 'FAIL'
}

const creators = {
  update: createAction(types.UPDATE),
  reset: createAction(types.RESET, status => status),
  fail: createAction(types.FAIL, (e, { errorTitleKey, errorMessageKey } = {}) => {
    captureSentryError(e)
    return {
      errorMessages: getErrorMessagesArray(e),
      errorTitleKey,
      errorMessageKey
    }
  })
}

const initialState = {
  status: 'init',
  currentPlayer: null,
  disableErrorActions: false,
  storageId: null,
  timeRemaining: null,
  played: false,
  errorMessages: null,
  errorTime: null,
  currentHlsManifestUrl: null
}
const PERSIST_AFTER_RESET_VARS = ['currentHlsManifestUrl']
const resetInitialState = _omit(initialState, PERSIST_AFTER_RESET_VARS)

const controlledResetState = (prevState) => ({
  ...resetInitialState,
  ..._pick(prevState, PERSIST_AFTER_RESET_VARS)
})

const actionsMap = {
  [types.UPDATE]: (prevState, payload) => {
    const newState = {
      ...prevState,
      ...(payload || {})
    }
    if (newState.status !== 'failed') {
      newState.errorMessages = null
      newState.errorTime = null
    }
    return newState
  },
  [types.RESET]: (prevState, status = 'start') => ({ ...controlledResetState(prevState), status }),
  [types.FAIL]: (prevState, { errorMessages = null, errorTitleKey = null, errorMessageKey = null, instance } = {}) => ({
    ...controlledResetState(prevState),
    status: 'failed',
    errorTitleKey,
    errorMessageKey,
    errorMessages,
    errorTime: moment.utc()
  })
}

const reducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  const fn = actionsMap[type]
  return fn ? fn(state, payload) : state
}

const LearnerCourseCloudflareStream = React.forwardRef(({
  slide, canGoPrevSlide, goToNextSlide, goToPrevSlide, courseResultId, waitMS, forceSubtitles = false
}, ref) => {
  const { id: slideId, title, content } = slide
  const { intro, hlsManifestUrl } = content || {}
  const [state, dispatch] = useReducer(reducer, initialState)

  const playerRef = useRef(null)

  const {
    status, currentPlayer, storageId, timeRemaining, played,
    errorMessages, errorTime, errorTitleKey, errorMessageKey,
    disableErrorActions, currentHlsManifestUrl
  } = state

  const isDemoModeEnabled = DemoMode.isEnabled()

  const slideDelayEnabled = useMemo(() => {
    return waitMS > 0 && !isDemoModeEnabled
  }, [waitMS, isDemoModeEnabled])

  const canProgress = useCallback(() => {
    return slideDelayEnabled ? played : true
  }, [slideDelayEnabled, played])

  const allowProgress = useMemo(() => canProgress(), [canProgress])

  useImperativeHandle(ref, () => ({
    canProgress
  }), [canProgress])

  const updateState = useCallback((newState) => dispatch(creators.update(newState)), [dispatch])
  const resetState = useCallback((status) => dispatch(creators.reset(status)), [dispatch])
  const setFailState = useCallback((e) => dispatch(creators.fail(e)), [dispatch])

  const onTryAgainClick = useCallback(async () => {
    resetState('start')
  }, [resetState])

  // Hooks into the Plyr and HLS lifecycles via the refs bubbled via LearnerCourseCloudflareStreamPlayer component
  const initialisePlayerListeners = useCallback(async ({ storageId, player, hls }) => {
    cleanupPlayerListeners(player)

    // Log errors from player instance to the console
    player.on('error', (error) => {
      if (error.message && error.method && error.name) {
        // Error was generated by the Plyr instance
        console.error(`LearnerCourseCloudflareStream.videoPlayer.instance - Method Error - method: ${error.method}; name: ${error.name}; message: ${error.message};`, error)
      } else {
        // All other errors
        console.error('LearnerCourseCloudflareStream.videoPlayer.instance - ERROR', error)
      }
    })

    // Primary initialisation event, takes place right after the 'ready' event, but is more useful as it
    // is the first event that can be used to interact with the player and contains metadata about the video (ie. duration)
    player.on('loadedmetadata', () => {
      const { currentTime, duration } = player
      const lastSavedTime = getSavedCurrentTime(storageId)

      if (_isNumber(lastSavedTime) && lastSavedTime > 0) {
        player.currentTime = lastSavedTime
      }

      if (forceSubtitles) {
        player.currentTrack = 0
        hls.subtitleTrack = player.currentTrack
      }

      updateState({
        played: lastSavedTime === 'played',
        timeRemaining: getVideoTimestampFromSecondsMatchingDuration(duration - currentTime, duration)
      })
    })

    player.on('timeupdate', async () => {
      if (!player.seeking) {
        const { currentTime, duration } = player
        // These values are in seconds to 6DP
        // Flooring them integer avoids an issue increases the likelihood of equality if timeupdate doesn't fire at the exact moment of completion
        const hasCompleted = Math.floor(duration) === Math.floor(currentTime)

        const update = {
          timeRemaining: getVideoTimestampFromSecondsMatchingDuration(duration - currentTime, duration)
        }

        if (hasCompleted) {
          localStorage.setItem(storageId, 'played')
          update.played = true
        } else {
          const lastSavedTime = getSavedCurrentTime(storageId)
          if (lastSavedTime !== 'played' && (_isNil(lastSavedTime) || currentTime > lastSavedTime)) {
            localStorage.setItem(storageId, currentTime)
          }
        }

        updateState(update)
      }
    })

    player.on('languagechange', () => {
      setTimeout(() => {
        if (player && player.currentTrack !== undefined) {
          hls.subtitleTrack = player.currentTrack
        }
      }, 50)
    })

    if (slideDelayEnabled) {
      player.on('seeking', () => {
        const lastSavedTime = getSavedCurrentTime(storageId)
        const currentTime = player.currentTime

        if (_isNumber(lastSavedTime) && currentTime > lastSavedTime) {
          // Video has been previously started and user is trying to advance past the further point they've played
          player.currentTime = lastSavedTime
          return
        }
        if (_isNil(lastSavedTime)) {
          // Video has never been started - reset to start on seek
          player.currentTime = 0
        }
        // Video has watched this part of the video is allowed forward/rewind/navigate to it
      })
    }
  }, [forceSubtitles, slideDelayEnabled, updateState])

  const startVideoPlayer = useCallback(async () => {
    if (!hlsManifestUrl) {
      console.error('LearnerCourseCloudflareStream.startVideoPlayer - No HLS Manifest URL present in course slide data')

      setFailState(new Error(`${I18n.t('misconfiguredSlideError', trOpt)} [NO_HSL_MANIFEST_URL]`))
      return
    }

    if (status !== 'start' && hlsManifestUrl === currentHlsManifestUrl) return
    // Set current video link as we're loading a new video
    updateState({ currentHlsManifestUrl: hlsManifestUrl })

    const newStorageId = `${ULEARN_CLOUDFLARESTREAM_STORAGE_PREFIX}${courseResultId}|${slideId}|${hlsManifestUrl}`
    const isNewInstance = !currentPlayer || newStorageId !== storageId

    if (isNewInstance) {
      // New Video instance required due to initialisation or transition between 2 video slides
      resetState('loading')

      // Establishes a new Video Player instance based off the component reference
      const { player, hls } = playerRef.current

      try {
        if (!player) {
          throw new Error(`${I18n.t('playerFailedToLoad', trOpt)} [PLYR_NOT_AVAILABLE]`)
        }
        if (!hls) {
          throw new Error(`${I18n.t('hlsNotSupportedError', trOpt)} [HSL_NOT_SUPPORTED]`)
        }

        // Handles errors caused by blocked domain requests
        await applyTimeout(
          new Promise((resolve, reject) => {
            const errorHandler = (event, data) => {
              if (data.type === 'networkError') {
                hls.off('hlsError', errorHandler)
                reject(new UsecureError(`${I18n.t('playerInitialisationTimeoutError', trOpt)} [HLS_NETWORK_ERROR]`))
              }
            }

            hls.on('hlsError', errorHandler)

            // Requires the HLS manifest to be parsed before the player can be interacted with
            hls.on('hlsManifestParsed', () => {
              hls.off('hlsError', errorHandler)
              initialisePlayerListeners({ storageId: newStorageId, player, hls })
              resolve()
            })
          }),
          {
            delay: VIDEO_TIMEOUT,
            timeoutError: `${I18n.t('playerInitialisationTimeoutError', trOpt)} [VIDEO_INIT_TIMEOUT]`,
            errorPrefix: 'Initialising video player'
          }
        )

        updateState({
          status: 'ready',
          storageId,
          currentPlayer: player
        })
      } catch (e) {
        console.error('LearnerCourseCloudflareStream.startVideoPlayer - Error creating new player instance', e)
        setFailState(e)
      }
    }
  }, [
    status, playerRef, currentPlayer, courseResultId, storageId, hlsManifestUrl, slideId,
    initialisePlayerListeners, currentHlsManifestUrl, updateState, resetState, setFailState
  ])

  // Reset state when slide changes
  useEffect(() => {
    resetState('start')
  }, [slideId, resetState])

  // "componentDidMount" effect
  useEffect(() => {
    dispatch(creators.update({
      status: 'start'
    }))
  }, [])

  useEffect(() => {
    startVideoPlayer()
  }, [startVideoPlayer])

  return (
    <ContentBox
      material innerKey={slideId}
      buttonsLeft={canGoPrevSlide ? <ProgressButton icon='arrow-left' label='common.previous' onClick={goToPrevSlide} /> : null}
      buttonsRight={<ProgressButton disabled={!allowProgress} onClick={goToNextSlide} />}
    >
      <CardTitle>{title}</CardTitle>
      <LearnerCourseSlate content={intro} />
      <StyledVideoWrapper>
        {status === 'failed' && (
          <ErrorDetail>
            <h1>{I18n.t(errorTitleKey || 'errorTitle', trOpt)}</h1>
            <h3>
              {I18n.t(errorMessageKey || 'errorMessage', trOpt)}
              <br />
              {I18n.t('common.pleaseContactSupport')}
            </h3>
            <ActionsContainer>
              <Button disabled={disableErrorActions} onClick={onTryAgainClick} icon='redo'>{I18n.t('tryAgainButton', trOpt)}</Button>
            </ActionsContainer>
            <DebugErrorOutput>
              {_isArray(errorMessages) && !_isEmpty(errorMessages) && errorMessages.every(e => _isString(e)) && renderParagraphsFragmentFromArray(errorMessages)}
              <p>{window.location.pathname}</p>
              <p>{I18n.t('debugTiming', { ...trOpt, currentTime: (errorTime || moment.utc()).format('YYYY-MM-DD HH:mm') })}</p>
              <p>{navigator.userAgent}</p>
            </DebugErrorOutput>
          </ErrorDetail>
        )}

        <LearnerCourseCloudflareStreamPlayer
          ref={playerRef}
          id={CONTAINER_ID}
          hlsManifest={hlsManifestUrl}
          status={status}
        />

        {status === 'ready' && _isString(timeRemaining) && <TimeRemaining>{I18n.t('learnerCourse.timeRemaining', { time: timeRemaining })}</TimeRemaining>}
      </StyledVideoWrapper>
    </ContentBox>
  )
})

export default LearnerCourseCloudflareStream
