import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Card, Button } from 'antd'
import queryString from 'query-string'
import styled from 'styled-components'
import _get from 'lodash/get'

import routes from '../../constants/routes'
import { Google } from '../../helpers'
import { LoadingBlock } from '../../components/common'
import { Link } from 'react-router-dom'
import GoogleSyncSelectAuthFlowModal from '../../components/Modals/GoogleSelectAuthFlowModal'
import { AUTH_REDIRECT_URL_ORIGIN } from '../../constants/environment'

const REDIRECT_URI = `${window.location.origin}/google-auth-test` // Fallback redirect URI for in progress features

const ContentWrap = styled.div`
  height: 100%;
  padding: 1rem 50px 5.809rem;
`

const ContentCard = styled(Card)`
  height: 100%;
  .ant-card-body {
    height: 100%;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const BodyContainer = styled.div`
  flex-grow: 1;
  overflow: auto;

  p {
    font-family: unset;
    font-size: 16px;
  }
`

const AuthTypeContainer = styled.div`
  margin-bottom: 20px;
`

const AuthButtonContainer = styled.div`
  max-width: 250px;
  width: 100%;
  .ant-btn {
    margin-bottom: 5px;
  }
`

// Add a new Google Cloud App to provide a quick auth flow for it
// Useful for building a new integration ahead of the final UI work
// You will need to add a client id and secret in the back end to provide delegated auth
const GOOGLE_AUTH_APPS = [{
  app: 'sync',
  title: 'Google Workspace Sync',
  redirectUri: `${AUTH_REDIRECT_URL_ORIGIN}${routes.GOOGLE_SYNC_AUTH_ENTRY_POINT}` // Override redirect URI to use existing post auth page
}, {
  app: 'messageInjection',
  title: 'Message Injection',
  authType: 'serviceAccount',
  delegatedUserEmailRequired: false
}]

const StartAuthButton = ({
  app, onClick: onClickProp = () => {}
}) => {
  const onClick = useCallback(() => onClickProp(app), [app, onClickProp])
  return (
    <Button type='primary' block onClick={onClick}>Start {app.title} Auth</Button>
  )
}

const GoBackButton = () => (
  <Link to={routes.GOOGLE_AUTH_TEST_START}>
    <Button type='primary'>Go Back</Button>
  </Link>
)

const GoogleAuthTest = ({ match, history }) => {
  const [status, setStatus] = useState('loading')
  const [authTypeTitle, setAuthTypeTitle] = useState(null)
  const updateAuth = useCallback(async ({ app, authType, authCode, redirectUri }) => {
    try {
      const google = new Google({ app, redirectUri })
      await google.updateAuthConfig({ authType, authCode })
      setStatus('complete')
    } catch (e) {
      console.error('GoogleAuthTest.updateAuth ERROR', e)
      setStatus('error')
    }
  }, [])
  useEffect(() => {
    const isStart = _get(match, 'path') === routes.GOOGLE_AUTH_TEST_START
    if (isStart) {
      setStatus('start')
    } else if (status === 'loading') {
      setStatus('updating')
      const parsedQueryString = queryString.parse(window.location.search)
      const { state: app, code: authCode } = parsedQueryString
      setAuthTypeTitle((GOOGLE_AUTH_APPS.find(type => type.app === app) || {}).title || '[NO AUTH TYPE]')
      history.push(routes.GOOGLE_AUTH_TEST)
      if (app && authCode) {
        updateAuth({
          app,
          authType: 'delegated',
          authCode,
          redirectUri: (GOOGLE_AUTH_APPS.find(type => type.app === app) || {}).redirectUri || REDIRECT_URI
        })
      } else {
        setStatus('error')
      }
    }
  }, [match, history, status, updateAuth])

  const selectAuthRef = useRef(null)
  const startAuth = useCallback(async ({ app, redirectUri = REDIRECT_URI, authType: fixedAuthType, delegatedUserEmailRequired = true }) => {
    if (selectAuthRef.current) {
      selectAuthRef.current.open({ app, redirectUri, fixedAuthType, delegatedUserEmailRequired })
    }
  }, [selectAuthRef])

  return (
    <ContentWrap>
      <LoadingBlock loading={status === 'loading' || status === 'updating'} />
      <GoogleSyncSelectAuthFlowModal ref={selectAuthRef} sameTab />
      <ContentCard>
        <ContentContainer>
          <h1>Google Auth Test Page</h1>
          <BodyContainer>
            {status === 'start' && (
              <AuthTypeContainer>
                {GOOGLE_AUTH_APPS.length > 0 ? (
                  <AuthButtonContainer>
                    {GOOGLE_AUTH_APPS.map((app, index) => <StartAuthButton key={index} onClick={startAuth} app={app} />)}
                  </AuthButtonContainer>
                ) : <p>None Configured</p>}
              </AuthTypeContainer>
            )}
            {status === 'complete' && (
              <>
                <h1>{authTypeTitle} Auth Complete</h1>
                <GoBackButton />
              </>
            )}
            {status === 'error' && (
              <>
                <h1>{authTypeTitle} Auth Failed</h1>
                <GoBackButton />
              </>
            )}
          </BodyContainer>
        </ContentContainer>
      </ContentCard>
    </ContentWrap>
  )
}

export default GoogleAuthTest
