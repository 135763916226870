import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Button, Popover, Table } from 'antd'
import styled from 'styled-components'
import { generatePath } from 'react-router-dom'
import _get from 'lodash/get'
import _pick from 'lodash/pick'
import I18n from 'i18n-js'

import ViewPolicyModal from '../../components/Modals/ViewPolicyModal'
import { ModalPager, LocalesCell, ListPopUpContainer } from '../../components/common'
import PolicyActions from '../../components/Policies/PolicyActions'
import PolicyVersionTag from '../../components/Policies/PolicyVersionTag'
import {
  CATEGORY_IDS, CATEGORY_NAMES, CATEGORY_OPTIONS,
  TEMPLATE_TYPE_NAMES, TEMPLATE_TYPE_IDS, TEMPLATE_TYPE_OPTIONS,
  SIGNATURE_TYPE_NAMES, SIGNATURE_TYPE_OPTIONS, SIGNATURE_TYPE_IDS
} from '../../constants/uPolicy'
import routes from '../../constants/routes'
import { processTableData } from '../../helpers'
import { ViewPolicyVersionFieldInfo } from '../../components/Policies/ViewPolicyVersionField'
import {
  onListTableSelectAll,
  onListTableChange
} from '../../helpers/listPages'
import { LIST_LOCALES_CELL_LIMIT, LIST_PAGINATION_PROPS } from '../../constants/list'
import TooltipIcon from '../../components/common/TooltipIcon'
import { NO_LANGUAGE_VALUE } from '../../constants/languages'
import { getSortedLanguageColumnFilterOptions } from '../../helpers/locale'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'uPolicy.policyTable' }

const PolicyNameLinkButton = styled(Button)`
  padding: 0;
`

const PolicyNameLink = ({ policy, onClick: onClickProp = () => {} }) => {
  const { id, name } = policy

  const onClick = useCallback(() => onClickProp(id), [onClickProp, id])

  return (
    <PolicyNameLinkButton type='link' onClick={onClick}>{name}</PolicyNameLinkButton>
  )
}

const TitleCell = styled.div`
  .anticon {
    color: #bfbfbf;
    font-size: 12px;
    margin-left: 5px;
  }

  h4 {
    font-size: 3px;
  }
`

const VersionTitle = ({ template, title }) => (
  <TitleCell>
    <span>{title}</span>
    <ViewPolicyVersionFieldInfo template={template} showInDraft showArchived={false} />
  </TitleCell>
)
const PolicyVersionTitle = () => <VersionTitle title={I18n.t('currentVersion', trOpt)} />
const PolicyTemplateStatusTitle = () => <VersionTitle template title={I18n.t('common.status')} />

const PolicySignatureTypeTitle = () => (
  <TitleCell>
    <span>{I18n.t('uPolicy.common.signatureType')}</span>
    <Popover
      title={I18n.t('policySignatureType', trOpt)}
      content={
        <>
          <p className='base-font'><PolicySignatureTypeDefault>*</PolicySignatureTypeDefault> - {I18n.t('thisPolicyIsUsingtheDefaultPolicy', trOpt)}</p>
          <h4>{SIGNATURE_TYPE_NAMES.fixed}</h4>
          <ul>
            <li>{I18n.t('thePolicyIsSentToUsersStateDate', trOpt)}</li>
            <li>{I18n.t('forExampleThePolicyIsSentOut', trOpt)}</li>
            <li>{I18n.t('itIsAlsoSentOutToNewUsers', trOpt)}</li>
          </ul>
          <h4>{SIGNATURE_TYPE_NAMES.lastSignature}</h4>
          <ul>
            <li>{I18n.t('thePolicyIsSentToUsersSignature', trOpt)}</li>
            <li>{I18n.t('itIsAlsoSentOutToNewUsers', trOpt)}</li>
          </ul>
          <h4>{SIGNATURE_TYPE_NAMES.newUsers}</h4>
          <ul>
            <li>{I18n.t('thePolicyIsSentToNewUsersWhenTheyAdded', trOpt)}</li>
            <li>{I18n.t('itIsNeverIssueForReSignatureAutomatically', trOpt)}</li>
          </ul>
          <h4>{SIGNATURE_TYPE_NAMES.none}</h4>
          <ul>
            <li>{I18n.t('thePolicyIsNotSentOutAutomatically', trOpt)}</li>
          </ul>
        </>
      }
    >
      <span><TooltipIcon /></span>
    </Popover>
  </TitleCell>
)

const PolicySignatureTypeDefault = styled.span`
  color: ${({ theme }) => theme.primary};
`

const PolicySignatureTypeCell = ({ theme, signatureType, useDefaultSignatureType }) => (
  <>
    <span>{SIGNATURE_TYPE_NAMES[signatureType]}</span>
    {useDefaultSignatureType && (
      <PolicySignatureTypeDefault>*</PolicySignatureTypeDefault>
    )}
  </>
)

const PoliciesTable = ({
  policies = [], loading = false,
  selectedPolicyIds = [], updateSelectedPolicyIds,
  sorter, updateSorter,
  filters, updateFilters,
  pagination, updatePagination,
  performAction = () => {},
  hasSuperPermission,
  type,
  builder,
  activePolicyId,
  updateActivePolicyId,
  routePolicyId,
  history,
  viewStorageId,
  externalAccess = false,
  externalAccessKey,
  showMultiSelectActions
}) => {
  const [showViewPolicyModal, updateShowViewPolicyModal] = useState(false)

  const openViewModel = useCallback(id => {
    updateActivePolicyId(id)
    updateShowViewPolicyModal(true)
  }, [updateActivePolicyId, updateShowViewPolicyModal])
  const closeModal = useCallback(() => updateShowViewPolicyModal(false), [updateShowViewPolicyModal])
  const afterModalClose = useCallback(() => {
    updateActivePolicyId(null)
    if (externalAccess) {
      history.push(generatePath(routes.EXTERNAL_POLICIES, { key: externalAccessKey }))
    } else {
      let route = routes.UPOLICY
      if (type === 'template') {
        route = builder ? routes.UPOLICY_TEMPLATE_BUILDER : routes.UPOLICY_TEMPLATES
      }
      history.push(route)
    }
  }, [updateActivePolicyId, history, type, builder, externalAccess, externalAccessKey])

  const openPolicy = useCallback((policyId) => {
    if (externalAccess) {
      history.push(generatePath(routes.EXTERNAL_POLICY, { policyId, key: externalAccessKey }))
    } else {
      let route = routes.UPOLICY_VIEW
      if (type === 'template') {
        route = builder ? routes.UPOLICY_TEMPLATE_BUILDER_VIEW : routes.UPOLICY_TEMPLATE_VIEW
      }
      history.push(generatePath(route, { policyId }))
    }
  }, [history, type, builder, externalAccess, externalAccessKey])

  const owners = useMemo(() => {
    if (!hasSuperPermission || policies.length === 0) {
      return []
    }

    return [
      { value: 'usecure', text: I18n.t('common.managedByUsecure') },
      { value: 'client', text: I18n.t(type === 'template' ? 'clientTemplates' : 'clientPolicies', trOpt) },
      ...policies
        .reduce((owners, { companyId, companyName }) => {
          if (companyId !== 'usecure' && companyId !== null &&
            !owners.some(o => o.value === companyId)) {
            owners.push({ value: companyId, text: companyName })
          }
          return owners
        }, [])
        .sort((a, b) => a.text.localeCompare(b.text))
    ]
  }, [policies, hasSuperPermission, type])

  const handlePerformAction = useCallback(async (action, policyId) => {
    updateActivePolicyId(policyId)
    performAction(action, [policyId])
  }, [performAction, updateActivePolicyId])

  const renderActionsCell = useCallback((actions, policy) => (
    <PolicyActions
      actions={actions}
      policyId={policy.id}
      performAction={handlePerformAction}
    />
  ), [handlePerformAction])
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const columns = useMemo(() => {
    const { columnKey: sortColumnKey, order } = sorter || {}

    const columns = [{
      title: I18n.t('common.fields.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text, policy) => <PolicyNameLink policy={policy} onClick={openPolicy}>{text}</PolicyNameLink>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortColumnKey === 'name' && order
    }]

    if (type === 'policy' || builder) {
      columns.push({
        title: type === 'template' ? PolicyTemplateStatusTitle : PolicyVersionTitle,
        dataIndex: 'versionNo',
        key: 'versionNo',
        render: (text, policy) => <PolicyVersionTag {..._pick(policy, ['isOwner', 'isLive', 'hasDraft', 'versionNo', 'lastMajorVersionNo'])} />
      })
    }

    if (hasSuperPermission) {
      columns.push({
        title: I18n.t('common.fields.owner'),
        dataIndex: 'companyName',
        key: 'companyName',
        filters: owners,
        filterMultiple: true,
        onFilter: (value, record) => {
          if (value === 'client') {
            return record.companyId !== 'usecure'
          }
          return record.companyId === value
        },
        filteredValue: _get(filters, 'companyName', null),
        sorter: (a, b) => {
          if (a.companyId === 'usecure' && b.companyId !== 'usecure') {
            return -1
          } else if (a.companyId !== 'usecure' && b.companyId === 'usecure') {
            return 1
          } else if (a.companyId === 'usecure' && b.companyId === 'usecure') {
            return 0
          }
          return a.companyName.localeCompare(b.companyName)
        },
        sortOrder: sortColumnKey === 'companyName' && order
      }, {
        title: I18n.t('common.accessControl.globalColumnLabel'),
        dataIndex: 'global',
        key: 'global',
        width: 240,
        sorter: (a, b) => {
          if (a.global && !b.global) {
            return -1
          } else if (!a.global && b.global) {
            return 1
          }
          return 0
        },
        sortOrder: sortColumnKey === 'global' && order,
        filters: [
          { value: true, text: I18n.t('common.yes') },
          { value: false, text: I18n.t('common.no') }
        ],
        filterMultiple: false,
        onFilter: (value, record) => (
          value === true ? record.global === true : record.global === false
        ),
        filteredValue: filters?.global ?? null,
        render: value => <div>{value ? I18n.t('common.yes') : I18n.t('common.no')}</div>
      })
    } else if (type === 'template' && !builder) {
      columns.push({
        title: I18n.t('common.type'),
        dataIndex: 'type',
        key: 'type',
        render: (text, { type: templateType }) => TEMPLATE_TYPE_NAMES[templateType],
        filters: TEMPLATE_TYPE_OPTIONS.map(({ value, label: text }) => ({ value, text })),
        filterMultiple: TEMPLATE_TYPE_OPTIONS.length > 2,
        onFilter: (value, record) => record.type === value,
        filteredValue: _get(filters, 'type', null),
        sorter: (a, b) => {
          const aIndex = TEMPLATE_TYPE_IDS.indexOf(a.type)
          const bIndex = TEMPLATE_TYPE_IDS.indexOf(b.type)
          return aIndex - bIndex
        },
        sortOrder: sortColumnKey === 'type' && order
      })
    }

    columns.push({
      title: I18n.t('common.category'),
      dataIndex: 'category',
      key: 'category',
      filters: CATEGORY_OPTIONS.map(({ value, label: text }) => ({ value, text })),
      filterMultiple: true,
      onFilter: (value, record) => record.category === value,
      filteredValue: _get(filters, 'category', null),
      render: (text, { category }) => CATEGORY_NAMES[category] || I18n.t('common.noCategory'),
      sorter: (a, b) => {
        const ids = ['none', ...CATEGORY_IDS]
        const aIndex = ids.indexOf(a.category)
        const bIndex = ids.indexOf(b.category)
        return aIndex - bIndex
      },
      sortOrder: sortColumnKey === 'category' && order
    })

    if (type === 'policy') {
      if (!builder && !externalAccess && hasAllSessionPermissions([permissions.POLICY_REPORT])) {
        columns.push({
          title: I18n.t('common.recipients'),
          dataIndex: 'recipients',
          key: 'recipients',
          sorter: (a, b) => a.recipients - b.recipients,
          sortOrder: sortColumnKey === 'recipients' && order
        }, {
          title: I18n.t('common.visited'),
          dataIndex: 'visited',
          key: 'visited',
          sorter: (a, b) => a.visited - b.visited,
          sortOrder: sortColumnKey === 'visited' && order
        }, {
          title: I18n.t('uPolicy.common.signed'),
          dataIndex: 'signed',
          key: 'signed',
          sorter: (a, b) => a.signed - b.signed,
          sortOrder: sortColumnKey === 'signed' && order
        })
      }

      columns.push({
        title: PolicySignatureTypeTitle,
        dataIndex: 'signatureType',
        key: 'signatureType',
        render: (signatureType, { useDefaultSignatureType }) => <PolicySignatureTypeCell {...{ signatureType, useDefaultSignatureType }} />,
        filters: SIGNATURE_TYPE_OPTIONS.map(({ value, label: text }) => ({ value, text })),
        filterMultiple: SIGNATURE_TYPE_OPTIONS.length > 2,
        onFilter: (value, record) => record.signatureType === value,
        filteredValue: filters?.signatureType ?? null,
        sorter: (a, b) => {
          const aIndex = SIGNATURE_TYPE_IDS.indexOf(a.signatureType)
          const bIndex = SIGNATURE_TYPE_IDS.indexOf(b.signatureType)
          return aIndex - bIndex
        },
        sortOrder: sortColumnKey === 'signatureType' && order
      })
    }

    if (type === 'template') {
      columns.push({
        title: I18n.t('common.languagesParenPlural'),
        dataIndex: 'locales',
        key: 'locales',
        filters: [
          { value: NO_LANGUAGE_VALUE, text: I18n.t('common.none') },
          ...getSortedLanguageColumnFilterOptions()
        ],
        filterMultiple: true,
        onFilter: (value, record) => value === NO_LANGUAGE_VALUE
          ? (!record.locales || record.locales.length === 0)
          : (record.locales && record.locales.includes(value)),
        filteredValue: _get(filters, 'locales', null),
        render: (locales, { name: itemName }) => <LocalesCell {...{ locales, itemName }} limit={LIST_LOCALES_CELL_LIMIT} />
      })
    }

    if ((type === 'policy' || builder) && !externalAccess) {
      columns.push({
        title: '',
        dataIndex: 'actions',
        align: 'right',
        render: renderActionsCell
      })
    }

    return columns
  }, [sorter, openPolicy, renderActionsCell, hasSuperPermission, filters, owners, type, builder, externalAccess, hasAllSessionPermissions])

  const onSelectChange = useCallback(selectedRowKeys => updateSelectedPolicyIds(selectedRowKeys), [updateSelectedPolicyIds])
  const onSelectAll = useCallback(selected => {
    onListTableSelectAll({ selected, updateSelectedIds: updateSelectedPolicyIds, filters, columns, records: policies })
  }, [policies, updateSelectedPolicyIds, filters, columns])

  const onTableChange = useCallback((pagination, updatedFilters, sorter) => {
    onListTableChange({
      pagination,
      updatePagination,
      sorter,
      updateSorter,
      prevFilters: filters,
      filters: updatedFilters,
      updateFilters,
      onSelectAll
    })
  }, [updatePagination, updateSorter, updateFilters, filters, onSelectAll])

  useEffect(() => {
    if (routePolicyId) {
      openViewModel(routePolicyId)
    } else {
      closeModal()
    }
  }, [routePolicyId, openViewModel, closeModal])

  const { policyCount, currentPolicyNum, prevPolicy, nextPolicy } = useMemo(() => {
    let currentPolicyNum
    let prevPolicy = null
    let nextPolicy = null

    const tPolicies = processTableData(columns, policies)
    if (activePolicyId && policies.length > 1) {
      const index = tPolicies.findIndex(({ id }) => id === activePolicyId)
      if (index !== -1) {
        currentPolicyNum = index + 1
        prevPolicy = index - 1
        prevPolicy = prevPolicy < 0 ? tPolicies.length - 1 : prevPolicy
        prevPolicy = (tPolicies[prevPolicy] || { id: null }).id
        nextPolicy = index + 1
        nextPolicy = nextPolicy > tPolicies.length - 1 ? 0 : nextPolicy
        nextPolicy = (tPolicies[nextPolicy] || { id: null }).id
      }
    }

    return {
      policyCount: tPolicies.length,
      currentPolicyNum,
      prevPolicy,
      nextPolicy
    }
  }, [activePolicyId, policies, columns])

  const popupContainerRef = useRef(null)
  const getPopupContainer = useCallback(() => {
    const { current: popupContainer } = popupContainerRef
    const fallbackContainer = document ? document.body : null

    return popupContainer || fallbackContainer
  }, [popupContainerRef])

  return (
    <>
      <ViewPolicyModal
        id={activePolicyId} visible={showViewPolicyModal} setVisible={updateShowViewPolicyModal}
        afterClose={afterModalClose}
        {...{ type, builder, viewStorageId, externalAccess, externalAccessKey }}
        title={<ModalPager count={policyCount || 1} current={currentPolicyNum} prev={prevPolicy} next={nextPolicy} onClick={openPolicy} />}
      />
      <ListPopUpContainer ref={popupContainerRef} />
      <Table
        columns={columns} dataSource={policies} loading={loading} onChange={onTableChange} pagination={{ ...pagination, ...LIST_PAGINATION_PROPS }}
        getPopupContainer={getPopupContainer}
        rowSelection={showMultiSelectActions ? {
          selectedRowKeys: selectedPolicyIds,
          onChange: onSelectChange,
          onSelectAll
        } : null}
      />
    </>
  )
}

export default PoliciesTable
