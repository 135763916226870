import axios from 'axios'

import { downloadFileFromGet } from '.'
import { getSessionToken } from './session'
import { apiUrl } from '../apollo-client/common'
import { getTimezoneHeader } from './timezone'

const DEFAULT_FILENAMES = {
  pdf: 'download.pdf',
  xlsx: 'download.xlsx'
}

const FILETYPE_TO_MIMETYPE = {
  pdf: 'application/pdf',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

class ExportServiceClient {
  constructor ({
    reportAccessToken,
    baseURL,
    onStart = () => {},
    onStatusChange = () => {},
    onFail = () => {},
    onFinish = () => {},
    fileName,
    fileType
  }) {
    this.client = this.getExportServiceClient(reportAccessToken, baseURL)
    this.jobId = null
    this.status = null
    this.errorMessage = null

    this.onStart = onStart
    this.onStatusChange = onStatusChange
    this.onFail = onFail
    this.onFinish = onFinish

    this.fileType = fileType
    this.fileName = fileName || DEFAULT_FILENAMES[fileType] || 'download'

    this.pollingInterval = 1000
    this.pollingCount = 0
    this.errorCount = 0
  }

  getExportServiceClient (reportAccessToken, baseURL) {
    return axios.create({
      baseURL: baseURL || `${apiUrl}/api/export`,
      headers: {
        'x-token': reportAccessToken || getSessionToken(),
        ...getTimezoneHeader()
      }
    })
  }

  setStatus (status) {
    if (this.status !== status) {
      this.status = status
      this.onStatusChange({ jobId: this.jobId, status: this.status, errorMessage: this.errorMessage })
    }
  }

  failJobFromError (error, errorLogMsg = 'ExportServiceClient.failJob') {
    this.failJob(error.response?.data?.error || error.message || `${error}`, { originalError: error, errorLogMsg })
  }

  failJob (errorMessage, { originalError, errorLogMsg = 'ExportServiceClient.failJob' } = {}) {
    if (originalError) console.error(errorLogMsg, originalError)
    this.errorMessage = errorMessage
    this.setStatus('failed')
    this.onFail({ jobId: this.jobId, status: this.status, errorMessage: this.errorMessage, originalError })
  }

  async execute (jobData) {
    await this.startJob(jobData)

    while (this.status === 'pending') {
      await this.getJobStatus()
      await new Promise(resolve => setTimeout(resolve, this.pollingInterval))
    }

    if (this.status === 'completed') {
      await this.downloadFile()
    }
  }

  async getJobStatus () {
    if (!this.jobId) this.failJob('noJobId')

    try {
      const response = await this.client.post(`/${this.fileType}/status/${this.jobId}`)
      const { status } = response.data

      this.errorCount = 0
      this.pollingCount++
      // Gradually increase the polling interval, based on the number of times we've polled, up to a max of 5 seconds
      this.pollingInterval = Math.min(1000 * Math.pow(1.1, this.pollingCount - 1), 5000)

      this.setStatus(status)
    } catch (e) {
      // Export service should send back an error message in the response for known errors
      // If an unexpected error occurs, we'll retry the job up to 3 times before failing
      this.errorCount++
      if (this.errorCount > 3) {
        this.failJobFromError(e)
      } else {
        // Double the polling interval
        this.pollingInterval *= 2
      }
    }
  }

  async startJob (jobData) {
    try {
      const response = await this.client.post(
        `/${this.fileType}/start`,
        { jobData }
      )

      const { jobId } = response.data
      if (jobId) {
        this.jobId = jobId
        this.setStatus('pending')
      } else {
        this.failJob('noJobIdReturned')
      }
    } catch (e) {
      // Export service should send back an error message in the response for known errors
      // If that isn't available, we'll just show the error message as is
      this.failJobFromError(e)
    }

    this.onStart({ jobId: this.jobId, status: this.status, errorMessage: this.errorMessage })
  }

  async downloadFile () {
    if (!this.jobId) this.failJob('noJobId')

    try {
      const downloadSuccess = await downloadFileFromGet({
        axiosInstance: this.client,
        endpoint: `/${this.fileType}/download/${this.jobId}`,
        file: this.fileName,
        type: FILETYPE_TO_MIMETYPE[this.fileType] || 'application/octet-stream'
      })
      if (downloadSuccess) {
        this.setStatus('downloaded')
      } else {
        this.failJob('downloadFailed')
      }
    } catch (e) {
      // Export service should send back an error message in the response for known errors
      // If that isn't available, we'll just show the error message as is
      this.failJobFromError(e)
    }

    this.onFinish({ jobId: this.jobId, status: this.status, errorMessage: this.errorMessage })
  }
}

export default ExportServiceClient
