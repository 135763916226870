import React, { useCallback, useRef } from 'react'
import I18n from 'i18n-js'
import styled from 'styled-components'
import { Button, Divider as _Divider, Collapse } from 'antd'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'

import SettingsForm from './SettingsForm'
import { BrandedMicrosoftAuthButton } from '../MicrosoftSync'
import routes from '../../constants/routes'
import { AUTH_REDIRECT_URL_ORIGIN } from '../../constants/environment'
import { Office365, renderParagraphsFragmentFromArray } from '../../helpers'
import GoogleSelectAuthFlowModal from '../Modals/GoogleSelectAuthFlowModal'
import { isMessageInjectionAuthorised, isMessageInjectionAuthorisedforGoogle, isMessageInjectionAuthorisedForM365 } from '../../helpers/messageInjection'
import RevokeIntegrationButton from '../Integrations/RevokeIntegrationButton'
import MessageInjectionTestModal from '../Modals/MessageInjectionTestModal'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'settings.messageInjection' }
const manageMicrosoftTrOpt = { scope: `${trOpt.scope}.manageMicrosoft` }
const manageGoogleTrOpt = { scope: `${trOpt.scope}.manageGoogle` }
const testTrOpt = { scope: `${trOpt.scope}.testUtility` }

const MS_REDIRECT_URI = `${AUTH_REDIRECT_URL_ORIGIN}${routes.MESSAGE_INJECTION_M365_AUTH_ENTRY_POINT}`
const MS_CLIENT_ID = window.__USECURE_CONFIG__.REACT_APP_M365_MESSAGE_INJECTION_CLIENT_ID

const { Panel } = Collapse

const Divider = styled(_Divider)`
  .ant-divider-inner-text {
    color: ${({ theme }) => theme.primary};
  }
`

const ManageMessageInjectionCollapse = styled(Collapse)`
  margin: 15px 0 20px;
`
const ManageMessageInjectionPanel = styled(Panel)`
  .ant-btn {
    margin-bottom: 15px;
  }
`

const _ManageMessageInjection = ({ className, hasAuth, authButton, testService, onTestClick, trOpt: serviceTrOpt, disabled = false }) => {
  let desc = I18n.t(hasAuth ? 'configuredDesc' : 'initialDesc', serviceTrOpt)
  if (!_isArray(desc)) {
    desc = [desc]
  }

  let content = null
  if (hasAuth) {
    content = (
      <>
        <MessageInjectionTestButton service={testService} onClick={onTestClick} disabled={disabled} />
        <ManageMessageInjectionCollapse>
          <ManageMessageInjectionPanel header={I18n.t('common.advancedOptions')}>
            <p>{I18n.t('reauthenticateIntro', serviceTrOpt)}</p>
            {authButton}
            <p>{I18n.t('revokeAuthIntro', serviceTrOpt)}</p>
            <RevokeIntegrationButton
              app='messageInjection'
              action='clearAuth'
              service={testService}
              trOpt={{ scope: `${serviceTrOpt.scope}.revokeAuth` }}
              disabled={disabled}
            />
          </ManageMessageInjectionPanel>
        </ManageMessageInjectionCollapse>
      </>
    )
  } else {
    content = authButton
  }

  return (
    <div {...{ className }}>
      <Divider>{I18n.t('title', serviceTrOpt)}</Divider>
      {renderParagraphsFragmentFromArray(desc)}
      {content}
    </div>
  )
}

const ManageMessageInjection = styled(_ManageMessageInjection)`
  margin-bottom: 30px;
`

const MessageInjectionTestButton = ({ service, onClick: onClickProp, disabled }) => {
  const onClick = useCallback(() => onClickProp(service), [onClickProp, service])
  return (
    <div>
      <p>{I18n.t('intro', testTrOpt)}</p>
      <Button type='primary' icon='mail' ghost onClick={onClick} disabled={disabled}>{I18n.t('runTestButton', testTrOpt)}</Button>
    </div>
  )
}

const ManageMessageInjectionM365 = ({ hasAuth, locale, onTestClick, disabled = false }) => {
  const onAuthClick = () => {
    if (disabled) return
    // Navigate to admin consent URL
    const office365 = new Office365({
      clientId: MS_CLIENT_ID,
      redirectUri: MS_REDIRECT_URI
    })
    window.open(office365.getAdminConsentUrl('messageInjection'), '_blank', ['noopener'])
  }
  return (
    <ManageMessageInjection
      hasAuth={hasAuth}
      trOpt={manageMicrosoftTrOpt}
      authButton={<BrandedMicrosoftAuthButton disabled={disabled} onClick={onAuthClick} {...{ locale }} />}
      testService='microsoft'
      onTestClick={onTestClick}
      disabled={disabled}
    />
  )
}

const ManageMessageInjectionGoogle = ({ hasAuth, onTestClick, disabled = false }) => {
  const selectAuthRef = useRef(null)
  const onAuthClick = useCallback(() => {
    if (disabled) return

    // Open service account configuration modal without delegated email field
    if (selectAuthRef.current) {
      selectAuthRef.current.open({ app: 'messageInjection', fixedAuthType: 'serviceAccount', delegatedUserEmailRequired: false })
    }
  }, [selectAuthRef, disabled])

  return (
    <>
      <GoogleSelectAuthFlowModal ref={selectAuthRef} />
      <ManageMessageInjection
        hasAuth={hasAuth}
        trOpt={manageGoogleTrOpt}
        authButton={<Button type='primary' disabled={disabled} icon='google' onClick={onAuthClick}>{I18n.t('configureButton', manageGoogleTrOpt)}</Button>}
        testService='google'
        onTestClick={onTestClick}
        disabled={disabled}
      />
    </>
  )
}

class MessageInjection extends SettingsForm {
  constructor (props) {
    super(props)

    this.settingIds = [
      'uPhishDeliveryMethod'
    ]
    this.defaultValue = {
      useMessageInjectionByDefault: false
    }
    this.setInitialFormValuesOnMount = true

    this.testUtilRef = React.createRef()

    this.onTestClick = this.onTestClick.bind(this)

    this.requiredUpdatePermissions = [permissions.SETTINGS_GENERAL_UPDATE]
  }

  get title () {
    return I18n.t('title', trOpt)
  }

  get description () {
    return I18n.t('description', trOpt)
  }

  get successMsg () {
    return I18n.t('formSuccessMessage', trOpt)
  }

  get failureMsg () {
    return I18n.t('formErrorMessage', trOpt)
  }

  get hasM365Auth () {
    return isMessageInjectionAuthorisedForM365(this.props.settings)
  }

  get hasGoogleAuth () {
    return isMessageInjectionAuthorisedforGoogle(this.props.settings)
  }

  get showForm () {
    // Show form when google or m365 message injection is auth'd
    return isMessageInjectionAuthorised(this.props.settings)
  }

  getFormValuesFromProps (props = this.props) {
    return {
      useMessageInjectionByDefault: _get(props.settings, 'uPhishDeliveryMethod') === 'messageInjection'
    }
  }

  get formValuesFromSettings () {
    return this.getFormValuesFromProps()
  }

  getCurrentAndPreviousValuesFromProps (prevProps) {
    return {
      setting: this.getFormValuesFromProps(this.props, false),
      prevSetting: this.getFormValuesFromProps(prevProps, false)
    }
  }

  get _fields () {
    return [{
      id: 'useMessageInjectionByDefault',
      type: 'switch',
      label: I18n.t('useMessageInjectionByDefault', trOpt),
      defaultValue: this.defaultValue.useMessageInjectionByDefault,
      extra: I18n.t('uPhish.common.messageInjectionDisclaimer')
    }]
  }

  mutateValues ({ useMessageInjectionByDefault }) {
    return {
      uPhishDeliveryMethod: useMessageInjectionByDefault ? 'messageInjection' : 'smtp'
    }
  }

  onTestClick (service) {
    if (this.testUtilRef.current) {
      this.testUtilRef.current.open(service)
    }
  }

  renderBeforeForm () {
    const authProps = { locale: this.props.locale, onTestClick: this.onTestClick, disabled: this.props.disableSubmit || !this.isUpdateAllowed }
    return (
      <>
        <MessageInjectionTestModal ref={this.testUtilRef} />
        <ManageMessageInjectionM365 hasAuth={this.hasM365Auth} {...authProps} />
        <ManageMessageInjectionGoogle hasAuth={this.hasGoogleAuth} {...authProps} />
        {this.showForm && <Divider>{I18n.t('common.additionalSettings')}</Divider>}
      </>
    )
  }
}

export default MessageInjection
