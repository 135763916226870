import posthog from 'posthog-js'

export const POSTHOG_ENABLED = window.__USECURE_CONFIG__.REACT_APP_POSTHOG_ENABLED === 'true'
export const POSTHOG_API_KEY = window.__USECURE_CONFIG__.REACT_APP_POSTHOG_API_KEY

// Allow for easy debugging.
window.posthog = posthog

if (POSTHOG_ENABLED) {
  posthog.init(POSTHOG_API_KEY, {
    api_host: 'https://eu.i.posthog.com',
    persistence: 'memory', // Enables cookie-less tracking.
    person_profiles: 'identified_only', // Will only track events for authenticated users.
    autocapture: false,
    session_recording: {
      maskAllInputs: false
    }
  })
}

/**
 * Identifies a user (admin) with PostHog
 */
export function identifyUserWithPosthog (data) {
  if (!POSTHOG_ENABLED) return

  console.debug('[PostHog] Identifying user')

  const {
    inDisguise,
    user: {
      id,
      email,
      name,
      companyId,
      company: {
        isUsecure,
        name: companyName,
        accountType,
        billingType,
        activeLearnerCount,
        uBreachProEnabled
      }
    }
  } = data

  // Identify the user, and attach user attributes.
  posthog.identify(id, { email, name, accountType: 'user' })

  // Register the user's company and it's associated attributes.
  // The company will reflect either the actual company or the one that the user is "disguised" as.
  posthog.group('company', companyId, { name: companyName, accountType, billingType, activeLearnerCount, uBreachProEnabled })

  // Register "super properties". These will be sent along for all events captured.
  posthog.register_for_session({
    isUsecure,
    inDisguise
  })
}

/**
 * Identifies an authenticated learner with PostHog
 */
export function identifyLearnerWithPosthog (learner) {
  if (!POSTHOG_ENABLED) return

  console.debug('[PostHog] Identifying learner')

  const {
    id,
    email,
    name,
    company: {
      id: companyId,
      name: companyName
    }
  } = learner

  // Identify the learner, and attach user attributes.
  posthog.identify(id, { email, name, accountType: 'learner' })

  // Register the learner's company and it's associated attributes.
  posthog.group('company', companyId, { name: companyName })
}

/**
 * Identifies an unauthenticated learner with PostHog.
 * Useful for pages that can be visited while not logged in, such as completing a course or signing a policy.
 */
export function identifyUnauthenticatedLearnerWithPosthog (learner) {
  if (!POSTHOG_ENABLED) return

  console.debug('[PostHog] Identifying unauthenticated learner')

  const {
    learnerId,
    learnerName,
    companyId
  } = learner

  if (learnerId && learnerName) {
    // Identify the learner, and attach user attributes.
    posthog.identify(learnerId, { name: learnerName, accountType: 'learner' })
  }

  if (companyId) {
    // Register the learner's company
    posthog.group('company', companyId)
  }
}

export function resetPosthog () {
  if (!POSTHOG_ENABLED) return

  console.debug('[PostHog] Resetting')
  posthog.reset()
}

/**
 * @description Captures an event in PostHog
 * @param {string} event - The event to capture
 * @param {Object} properties - Optional additional properties to attach to the event
 */
export function capturePosthogEvent (event, properties) {
  if (!POSTHOG_ENABLED) return

  console.debug('[PostHog] Capturing event', event, properties)
  posthog.capture(event, properties)
}
